import { Navigate, useRoutes } from 'react-router-dom';

import AuthUserAware from 'src/routes/wrapper/auth-user-aware';
import InternalUserAware from 'src/routes/wrapper/internal-user-aware';

import MainLayout from 'src/layouts/main';
import RobertPage from 'src/pages/robert';
import ImprintPage from 'src/pages/imprint';
import PrivacyPage from 'src/pages/privacy';
import WaitlistPage from 'src/pages/waitlist';
import RegisterPage from 'src/pages/register';
import CompactLayout from 'src/layouts/compact';
import EmailConfirm from 'src/pages/email-confirm';
import ProjectGroupAnonPage from 'src/pages/project-group-anon';

import Onboarding from 'src/sections/onboarding';

// import { PATH_AFTER_LOGIN } from 'src/config-global';
import { HomePage, mainRoutes } from './main';
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },
    {
      path: '/waitlist',
      element: (
        <MainLayout>
          <WaitlistPage />
        </MainLayout>
      ),
    },
    {
      path: '/robert',
      element: (
        <MainLayout>
          <RobertPage />
        </MainLayout>
      ),
    },
    {
      path: '/register',
      element: (
        <MainLayout>
          <RegisterPage />
        </MainLayout>
      ),
    },
    {
      path: '/imprint',
      element: (
        <MainLayout>
          <ImprintPage />
        </MainLayout>
      ),
    },
    {
      path: '/privacy',
      element: (
        <MainLayout>
          <PrivacyPage />
        </MainLayout>
      ),
    },
    {
      path: '/email-confirm',
      element: (
        <MainLayout>
          <EmailConfirm />
        </MainLayout>
      ),
    },
    {
      path: '/onboarding',
      element: (
        <CompactLayout>
          <AuthUserAware>
            <InternalUserAware>
              <Onboarding />
            </InternalUserAware>
          </AuthUserAware>
        </CompactLayout>
      ),
    },
    {
      path: '/p/:slug/:groupId',
      element: (
        <MainLayout>
          <ProjectGroupAnonPage />
        </MainLayout>
      ),
    },

    ...dashboardRoutes,
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
