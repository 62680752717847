import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import Grid from '@mui/system/Unstable_Grid';
import { Box, Stack, Container } from '@mui/system';
import { Card, Button, Divider, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useProjectGroup } from 'src/hooks/use-project-group';

import { slugify } from 'src/utils/slugify';

import { HEADER } from 'src/layouts/config-layout';
import GroupInfoPostingItem from 'src/pages/dashboard/management/group/info/posting-item';
import GroupAggregatedSkills from 'src/pages/dashboard/management/group/aggregated-skills';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export default function ProjectGroupAnonView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const settings = useSettingsContext();
  const { slug, groupId } = useParams();
  const projectId = searchParams.get('projectId') || null;

  const { data: group, isLoading } = useProjectGroup(groupId);

  const expandPosting = (id) => () => {
    if (group?.postingCount === 1 || projectId === id) {
      return;
    }

    setSearchParams({ ...searchParams, projectId: id });
  };

  useEffect(() => {
    if (group?.postingCount > 1 && !projectId) {
      setSearchParams({ ...searchParams, projectId: group.postings[0].projectId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group?.postingCount, projectId]);

  if (isLoading) {
    return <LoadingScreen title="projectfit.ai" />;
  }

  if (!group?._id) {
    return <Navigate to={paths.page404} />;
  }

  const expectedSlug = slugify(group.title);

  if (slug !== expectedSlug) {
    return <Navigate to={paths.projectGroupDetails(expectedSlug, group._id)} />;
  }

  return (
    <>
      <Helmet>
        <title>projectfit.ai | {group.title}</title>
      </Helmet>

      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          backgroundImage: `url('/assets/background/overlay_7_gradient.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />

      <Container
        maxWidth={settings.themeStretch ? false : 'lg'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 1,
          flexGrow: 1,
          py: {
            xs: `${HEADER.H_MOBILE}px`,
            sm: `${HEADER.H_DESKTOP}px`,
          },
        }}
      >
        <Stack direction="column" spacing={3} sx={{ pb: 5 }}>
          <Typography component="h1" variant="h4">
            {group.title}
          </Typography>

          {/* SKILLS */}
          <GroupAggregatedSkills item={group} />

          <Grid container spacing={3}>
            <Grid xs={12} md={4} order={{ md: 1 }}>
              <Card>
                <Stack direction="column" spacing={2} sx={{ py: 2 }}>
                  <Stack direction="row" spacing={2} sx={{ px: 2 }}>
                    <Iconify icon="material-symbols-light:rocket-launch" />
                    <Typography variant="body" sx={{ fontWeight: 'bold' }}>
                      Unlock all benefits by logging in!
                    </Typography>
                  </Stack>

                  <Typography variant="body2" sx={{ px: 2 }}>
                    As a registered user, you&apos;ll not only get{' '}
                    <Typography variant="body2" sx={{ fontWeight: 700 }} component="span">
                      detailed insights
                    </Typography>{' '}
                    into how your profile aligns with this project – you will also automatically get{' '}
                    <Typography variant="body2" sx={{ fontWeight: 700 }} component="span">
                      personalized notifications
                    </Typography>{' '}
                    for other exciting opportunities that match your skills.
                  </Typography>

                  <Divider />

                  <Stack direction="column" sx={{ px: 2 }}>
                    <Button
                      href={`${paths.login}?returnTo=${encodeURIComponent(
                        paths.dashboard.management.group(group?._id)
                      )}`}
                      variant="contained"
                      color="primary"
                    >
                      Login and see your insights
                    </Button>
                  </Stack>
                </Stack>
              </Card>
            </Grid>

            <Grid xs={12} md={8} order={{ md: 0 }}>
              <Card>
                {group.postings.map((posting, i) => (
                  <GroupInfoPostingItem
                    key={posting.projectId}
                    groupId={group._id}
                    postingCount={group.postingCount}
                    posting={posting}
                    isExpanded={projectId === posting.projectId || (!projectId && i === 0)}
                    expand={expandPosting(posting.projectId)}
                  />
                ))}
              </Card>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </>
  );
}
