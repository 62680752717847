import PropTypes from 'prop-types';

import { Stack } from '@mui/material';

import ProjectSkill from 'src/components/project-skill';

// ----------------------------------------------------------------------

export default function GroupAggregatedSkills({ item, user }) {
  if (!item?.skills?.labels) {
    return null;
  }

  return (
    <Stack direction="row" flexWrap="wrap" spacing={1}>
      {item.skills.labels.map((tag) => (
        <ProjectSkill source="project-group" key={tag} skill={tag} user={user} userId={user?._id} />
      ))}
    </Stack>
  );
}

GroupAggregatedSkills.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    postingCount: PropTypes.number,
    postings: PropTypes.arrayOf(
      PropTypes.shape({
        projectId: PropTypes.string,
        postedAt: PropTypes.string,
        platform: PropTypes.string,
        sourceLink: PropTypes.string,
        status: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        details: PropTypes.shape({
          descriptionLanguage: PropTypes.string,
          authorCompany: PropTypes.string,
          authorName: PropTypes.string,
          hourlyRate: PropTypes.number,
          startDate: PropTypes.string,
          workload: PropTypes.string,
          durationInMonths: PropTypes.number,
          industry: PropTypes.string,
          contractType: PropTypes.string,
          workplace: PropTypes.string,
          location: PropTypes.string,
        }),
      })
    ),
    details: PropTypes.shape({
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
    skills: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
      references: PropTypes.arrayOf(PropTypes.string),
    }),
    userDetails: PropTypes.shape({
      opinion: PropTypes.shape({
        projectId: PropTypes.string,
        comment: PropTypes.string,
        level: PropTypes.number,
        createdAt: PropTypes.string,
      }),
      matching: PropTypes.shape({
        projectId: PropTypes.string,
        createdAt: PropTypes.string,
        status: PropTypes.string,
        result: PropTypes.shape({
          information: PropTypes.string,
          level: PropTypes.number,
          missingSkills: PropTypes.arrayOf(PropTypes.string),
          missingSkillsReferences: PropTypes.arrayOf(PropTypes.string),
          personalAnalysis: PropTypes.string,
        }),
      }),
      applicationCoverLetters: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          projectId: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          createdAt: PropTypes.string,
        })
      ),
    }),
  }),
  user: PropTypes.object,
};
