import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { m, AnimatePresence } from 'framer-motion';
import { useRef, useMemo, useState, useEffect, useCallback } from 'react';

import { Stack } from '@mui/system';
import Container from '@mui/material/Container';

import { useScopes } from 'src/hooks/use-scopes';
import { useDocuments } from 'src/hooks/use-documents';
import { useUserById } from 'src/hooks/use-user-by-id';

import { getOnboardingConfig } from 'src/utils/get-onboarding-config';

import { LoadingScreen } from 'src/components/loading-screen';
import { varFade, MotionContainer } from 'src/components/animate';

// ----------------------------------------------------------------------

export default function OnboardingPage() {
  const initializedConfig = useRef(false);
  const navigate = useNavigate();
  const { scopes, hasScope } = useScopes();
  const { data: user, isLoading } = useUserById();
  const [currentStep, setCurrentStep] = useState(null);
  const [queuedStep, queueStep] = useState(null);
  const [availableSteps, setAvailableSteps] = useState();
  const { data: documents } = useDocuments(user?._id);

  const [animations, setAnimations] = useState({
    initial: varFade().inRight.initial,
    animate: varFade().inRight.animate,
    exit: varFade().inLeft.exit,
  });

  useEffect(() => {
    if (queuedStep) {
      setCurrentStep(queuedStep);
      queueStep(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animations]);

  const cvDocument = useMemo(() => {
    const document = (documents?.data || [])?.find((d) => d.type === 'CV');

    if (!document) {
      return undefined;
    }

    return document;
  }, [documents]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentStep]);

  useEffect(() => {
    // we only want to update the available steps if the initial state has been calculated before
    if (!initializedConfig.current) {
      return;
    }

    const onboardingConfig = getOnboardingConfig(hasScope, cvDocument, user?.onboarding);

    setAvailableSteps(onboardingConfig.availableSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cvDocument]);

  useEffect(() => {
    // if the user has not been loaded yet, wait for it
    if (!user?._id || scopes.length === 0) {
      return;
    }

    // we only want to initialize the onboarding config once
    if (initializedConfig.current) {
      return;
    }

    const onboardingConfig = getOnboardingConfig(hasScope, cvDocument, user?.onboarding);

    initializedConfig.current = true;

    setAvailableSteps(onboardingConfig.availableSteps);
    setCurrentStep(onboardingConfig.defaultPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopes, user?._id, user?.onboarding]);

  const previousPage = useCallback(() => {
    setAnimations({
      initial: varFade().inLeft.initial,
      animate: varFade().inLeft.animate,
      exit: varFade().inRight.exit,
    });

    const currentIndex = availableSteps.findIndex((step) => step.id === currentStep);

    if (currentIndex === 0) {
      return;
    }

    queueStep(availableSteps[currentIndex - 1].id);
  }, [availableSteps, currentStep]);

  const nextPage = useCallback(() => {
    setAnimations({
      initial: varFade().inRight.initial,
      animate: varFade().inRight.animate,
      exit: varFade().inLeft.exit,
    });

    const currentIndex = availableSteps.findIndex((step) => step.id === currentStep);

    if (currentIndex === availableSteps.length - 1) {
      return;
    }

    queueStep(availableSteps[currentIndex + 1].id);
  }, [availableSteps, currentStep]);

  const goToDashboard = useCallback(() => {
    navigate('/dashboard');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || !currentStep || !user?._id) {
    return <LoadingScreen title="projectfit.ai | Start your journey" />;
  }

  return (
    <>
      <Helmet>
        <title>projectfit.ai | Start your journey</title>
      </Helmet>

      <Container component={MotionContainer}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '100%',
            height: 1,
            mx: 'auto',
          }}
        >
          <AnimatePresence mode="wait">
            <m.div
              key={currentStep}
              initial={animations.initial}
              animate={animations.animate}
              exit={animations.exit}
            >
              {availableSteps
                .filter((step) => step.id === currentStep)
                .map((step) => {
                  const stepNumber = availableSteps.findIndex((s) => s.id === step.id) + 1;
                  const isFirstStep = stepNumber === 1;
                  const isLastStep = stepNumber === availableSteps.length;
                  const Component = step.component;

                  return (
                    <Component
                      user={user}
                      cvDocument={cvDocument}
                      key={step.id}
                      percentage={((stepNumber - 1) / (availableSteps.length - 1)) * 100}
                      prev={isFirstStep ? null : previousPage}
                      next={isLastStep ? goToDashboard : nextPage}
                    />
                  );
                })}
            </m.div>
          </AnimatePresence>
        </Stack>
      </Container>
    </>
  );
}
