import { m } from 'framer-motion';
import Lottie from 'react-lottie';
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';

import Typography from '@mui/material/Typography';
import { Button, LinearProgress } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';

import CompactLayout from 'src/layouts/compact';
import animationData from 'src/assets/lotties/check.json';
import SeverErrorIllustration from 'src/assets/illustrations/sever-error-illustration';

import { varBounce, MotionContainer } from 'src/components/animate';

// ----------------------------------------------------------------------

export default function EmailConfirmView() {
  const api = useApi(false);
  const loading = useBoolean(false);
  const success = useBoolean(false);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const [userId, code] = [searchParams.get('userId'), searchParams.get('code')];

  const confirmEmail = useCallback(async () => {
    loading.onTrue();

    try {
      await api.put(`/v1/users/${userId}/email`, {
        code,
      });

      success.onTrue();
    } catch (e) {
      console.error(e);
      success.onFalse();
      setError(error);
    } finally {
      loading.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userId || !code) {
      success.onFalse();
      setError('Your url is invalid, please click again on the link in your email');
      return;
    }

    setError(null);
    confirmEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading.value) {
    return (
      <CompactLayout>
        <MotionContainer>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" sx={{ mb: 5 }}>
              Confirming your email ...
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <LinearProgress color="inherit" sx={{ width: 1 }} />
          </m.div>
        </MotionContainer>
      </CompactLayout>
    );
  }

  if (!success.value) {
    return (
      <CompactLayout>
        <MotionContainer>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" sx={{ mb: 2 }}>
              There was an error
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              {error || 'We could not confirm your email address, please try again later'}
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
          </m.div>
        </MotionContainer>
      </CompactLayout>
    );
  }

  return (
    <CompactLayout>
      <MotionContainer>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Thank you!
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Your email has been confirmed! You will now receive immediate notifications to your
            email address.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            width={196}
            height={196}
          />
        </m.div>

        <m.div variants={varBounce().in}>
          <Button
            LinkComponent={RouterLink}
            href={paths.dashboard.root}
            size="large"
            variant="contained"
            sx={{ mt: 3 }}
          >
            Continue
          </Button>
        </m.div>
      </MotionContainer>
    </CompactLayout>
  );
}
