import { m } from 'framer-motion';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Pagination, CircularProgress } from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useInbox } from 'src/hooks/use-inbox';
import { useBoolean } from 'src/hooks/use-boolean';
import { useUserById } from 'src/hooks/use-user-by-id';
import { useResponsive } from 'src/hooks/use-responsive';

import NotificationsEmptyState from 'src/layouts/common/notifications-popover/empty-state';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';

import NotificationItem from './notification-item';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'UNREAD',
    label: 'Unread',
  },
  {
    value: 'ALL',
    label: 'All',
  },
];

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const sidebarOpen = useBoolean();
  const smUp = useResponsive('up', 'sm');
  const { data: user } = useUserById();
  const api = useApi();

  /**
   * TAB HANDLING
   */
  const [currentTab, setCurrentTab] = useState(
    localStorage.getItem(`user-notifications_${user?._id}_tab`) || TABS[0].value
  );

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  useEffect(() => {
    localStorage.setItem(`user-notifications_${user?._id}_tab`, currentTab);
  }, [currentTab, user?._id]);

  /**
   * DATA FETCHING
   */
  const itemsPerPage = 25;
  const [currentPage, setCurrentPage] = useState(1);

  const handleChangePage = useCallback((_, newValue) => {
    setCurrentPage(newValue);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  const { data: inboxItems, mutate } = useInbox(
    user?._id,
    currentTab !== 'ALL' ? currentTab : undefined,
    (currentPage - 1) * itemsPerPage,
    itemsPerPage,
    sidebarOpen.value
  );

  /**
   * PAGINATION COUNT
   */
  const [tabCounts, setTabCounts] = useState({
    ALL: 0,
    UNREAD: 0,
    READ: 0,
  });
  const maxPages = useMemo(
    () => Math.ceil((inboxItems?.metadata?.[0]?.total || 0) / itemsPerPage),
    [inboxItems?.metadata]
  );

  useEffect(() => {
    if (!inboxItems?.aggregations?.UNREAD && !inboxItems?.aggregations?.READ) {
      return;
    }

    const read = inboxItems.aggregations?.READ || 0;
    const unread = inboxItems.aggregations?.UNREAD || 0;

    setTabCounts({
      ALL: read + unread,
      UNREAD: unread,
      READ: read,
    });
  }, [inboxItems]);

  /**
   * STATUS UPDATES
   */
  const [markAsReadLoading, setMarkAsReadLoading] = useState(null);

  const markAllItemsAsRead = useCallback(async () => {
    setMarkAsReadLoading('ALL');

    try {
      await api.patch(`/v1/inbox`, {
        userId: user._id,
        status: 'READ',
      });

      mutate();
    } catch (error) {
      console.error(error);
    } finally {
      setMarkAsReadLoading(null);
    }
  }, [api, user._id, mutate]);

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!!tabCounts.UNREAD && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={markAllItemsAsRead}>
            {markAsReadLoading === 'ALL' ? (
              <CircularProgress size={16} />
            ) : (
              <Iconify icon="eva:done-all-fill" />
            )}
          </IconButton>
        </Tooltip>
      )}

      {!smUp && (
        <IconButton onClick={sidebarOpen.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'ALL' || tab.value === currentTab) && 'filled') || 'soft'}
              color={
                (tab.value === 'UNREAD' && 'info') ||
                (tab.value === 'READ' && 'success') ||
                'default'
              }
            >
              {tabCounts[tab.value]}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {(inboxItems?.data || []).map((notification) => (
          <NotificationItem
            key={notification._id}
            notification={notification}
            mutate={mutate}
            closeDrawer={sidebarOpen.onFalse}
          />
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={sidebarOpen.value ? 'primary' : 'default'}
        onClick={sidebarOpen.onTrue}
      >
        <Badge badgeContent={tabCounts.UNREAD || 0} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={sidebarOpen.value}
        onClose={sidebarOpen.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
        </Stack>

        <Divider />

        {(inboxItems?.data || []).length === 0 ? <NotificationsEmptyState /> : renderList}

        {maxPages > 1 && (
          <>
            <Divider />
            <Stack sx={{ p: 1, width: 1 }} alignItems="center">
              <Pagination
                count={maxPages}
                page={currentPage}
                siblingCount={0}
                boundaryCount={1}
                onChange={handleChangePage}
              />
            </Stack>
          </>
        )}
      </Drawer>
    </>
  );
}
