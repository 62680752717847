import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Navigate, useParams } from 'react-router-dom';

import { useUserById } from 'src/hooks/use-user-by-id';
import { useAuthState } from 'src/hooks/use-auth-state';

import { LoadingScreen } from 'src/components/loading-screen';

export default function InternalUserAware({ children }) {
  const { userId: authUserId } = useAuthState();
  const { userId } = useParams();
  const { data: user, isLoading } = useUserById(userId);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!user) {
    Sentry.setContext('authUserId', authUserId);
    Sentry.captureException(new Error('InternalUserAware: User not found'));
    return <Navigate replace to="/logout" />;
  }

  return children;
}

InternalUserAware.propTypes = {
  children: PropTypes.node.isRequired,
};
