import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Card, Alert, Button } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';

import { bgGradient } from 'src/theme/css';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

// ----------------------------------------------------------------------

const ForgotPasswordSchema = Yup.object().shape({
  emailAddress: Yup.string().email().required('email address is required'),
});

// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
  const api = useApi();
  const theme = useTheme();
  const isLoading = useBoolean(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const returnTo = searchParams.get('returnTo');

  const methods = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues: {
      emailAddress: searchParams.has('email')
        ? decodeURIComponent(searchParams.get('email') || '')
        : '',
    },
  });

  const emailAddress = methods.watch('emailAddress');

  const forwardParams = new URLSearchParams();
  if (emailAddress) {
    forwardParams.set('email', emailAddress);
  }
  if (returnTo) {
    forwardParams.set('returnTo', returnTo);
  }

  const onSubmit = methods.handleSubmit(async (data) => {
    isLoading.onTrue();
    setErrorMessage(null);

    try {
      await Promise.all([
        api.post('/v1/auth/forgot-password', { emailAddress: data.emailAddress }),
        new Promise((resolve) => setTimeout(resolve, 2000)),
      ]);

      navigate(`${paths.resetPassword}${forwardParams.size > 0 ? `?${forwardParams}` : ''}`);
    } catch (error) {
      isLoading.onFalse();
      const message = error?.response?.data?.error || 'An error occurred. Please try again later.';
      setErrorMessage(message);
    }
  });

  return (
    <Box sx={{ maxWidth: '400px' }}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          opacity: 0.24,
          ...bgGradient({
            direction: '180deg',
            startColor: alpha(theme.palette.grey[900], 0),
            endColor: alpha(theme.palette.grey[900], 0.1),
          }),
        }}
      />

      <FormProvider methods={methods} onSubmit={onSubmit} sx={{ position: 'relative' }}>
        <Card sx={{ p: 3, position: 'relative' }}>
          <Stack direction="column" width={1} alignItems="flex-start" spacing={3}>
            <Stack direction="column" width={1} alignItems="center" spacing={3}>
              <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
                Forgot your password?
              </Typography>

              <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>
                Please enter the email address associated with your account and we&apos;ll email you
                a link to reset your password.
              </Typography>
            </Stack>

            <Stack
              spacing={1}
              direction="column"
              justifyContent="flex-start"
              textAlign="left"
              sx={{
                width: '100%',
              }}
            >
              <RHFTextField
                name="emailAddress"
                label="Email Address"
                inputProps={{
                  sx: { backgroundColor: 'white', borderRadius: '8px' },
                }}
              />

              {errorMessage && (
                <Alert severity="error" sx={{ width: 1 }}>
                  {errorMessage}
                </Alert>
              )}
            </Stack>

            <Stack direction="column" width={1} spacing={1.5}>
              <LoadingButton
                type="submit"
                loading={methods.formState.isSubmitting}
                color="primary"
                size="large"
                variant="contained"
                width={1}
              >
                Send request
              </LoadingButton>
              <Button
                LinkComponent={RouterLink}
                href={`${paths.login}${forwardParams.size > 0 ? `?${forwardParams}` : ''}`}
                color="inherit"
                size="small"
                variant="text"
                startIcon={<Iconify icon="solar:alt-arrow-left-linear" width={16} />}
                width={1}
              >
                Return to sign in
              </Button>
            </Stack>
          </Stack>
        </Card>
      </FormProvider>
    </Box>
  );
}
