import PropTypes from 'prop-types';
import { Navigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import { useBoolean } from 'src/hooks/use-boolean';
import { useUserById } from 'src/hooks/use-user-by-id';
import { useResponsive } from 'src/hooks/use-responsive';
import { useProjectProviders } from 'src/hooks/use-project-providers';

import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';
import EnvironmentBanner from 'src/components/environment-banner/environment-banner';

import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  // load the most important data before everything else
  const params = useParams();
  const { data: ownUser, isLoading: isUserLoading } = useUserById();
  const { isLoading: isImpersonatedUserLoading } = useUserById(params.userId);
  const { isLoading: isProviderLoading } = useProjectProviders();

  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  if (isUserLoading || isImpersonatedUserLoading || isProviderLoading) {
    return <LoadingScreen />;
  }

  // if there is no user record yet, or if you haven't finished your onboarding -> do it now
  if ((!isUserLoading && !ownUser) || (ownUser && !ownUser?.onboarding?.total?.finished)) {
    return <Navigate replace to="/onboarding" />;
  }

  if (isHorizontal) {
    return (
      <>
        <EnvironmentBanner />
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <EnvironmentBanner />
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <EnvironmentBanner />
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
