import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Stack,
  Dialog,
  Tooltip,
  useTheme,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogContentText,
} from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useScopes } from 'src/hooks/use-scopes';
import { useBoolean } from 'src/hooks/use-boolean';
import { useUserById } from 'src/hooks/use-user-by-id';
import { isDocumentInProgress } from 'src/hooks/use-documents';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

// ----------------------------------------------------------------------

export default function DocumentRow({ userId, document, onDelete }) {
  const api = useApi();
  const theme = useTheme();
  const { data: user } = useUserById(userId);
  const { enqueueSnackbar } = useSnackbar();
  const deleteConfirm = useBoolean(false);
  const deleteProgress = useBoolean(false);
  const infoDialog = useBoolean(false);
  const { hasScope } = useScopes();

  const icon = useMemo(() => {
    switch (document.fileInfo.fileType) {
      case 'application/pdf':
        return 'bxs:file-pdf';
      case 'application/msword':
        return 'bxs:file-doc';
      case 'application/json':
        return 'bxs:file-json';
      case 'text/x-markdown':
      case 'plain/text':
        return 'bxs:file-md';
      case 'image/png':
        return 'bxs:file-png';
      case 'image/gif':
        return 'bxs:file-gif';
      case 'image/jpeg':
      case 'image/jpg':
        return 'bxs:file-jpg';
      default:
        return 'bxs:file-blank';
    }
  }, [document.fileInfo.fileType]);

  const handleDeleteDocument = async (id) => {
    deleteProgress.onTrue();

    try {
      await api.delete(`/v1/documents/${id}`, { params: { userId: user._id } });
      onDelete();
    } catch (e) {
      enqueueSnackbar('Could not delete document!', { variant: 'error' });
    } finally {
      deleteProgress.onFalse();
      deleteConfirm.onFalse();
    }
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{ display: 'flex', width: 1 }}
      >
        <Iconify icon={icon} height={24} sx={{ flexShrink: 0 }} />

        <Box sx={{ flexGrow: 1 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="caption" title={document.fileInfo.fileName}>
              <LinesEllipsisLoose
                text={`${document.fileInfo.fileName}`}
                maxLine="1"
                lineHeight="20"
              />
            </Typography>

            {!isDocumentInProgress(document.status) && (
              <Tooltip
                arrow
                title={
                  document.status === 'PARSED'
                    ? 'Document has been successfully parsed'
                    : `Could not parse document: ${document.error}`
                }
              >
                <Iconify
                  color={
                    document.status === 'PARSED'
                      ? theme.palette.success.main
                      : theme.palette.error.main
                  }
                  icon={
                    document.status === 'PARSED' ? 'eva:checkmark-outline' : 'eva:close-circle-fill'
                  }
                  width={16}
                  height={16}
                  flexShrink={0}
                />
              </Tooltip>
            )}
          </Stack>
        </Box>

        {isDocumentInProgress(document.status) && (
          <Box sx={{ width: '22px', height: '22px', flexShrink: 0 }}>
            <Tooltip title="We are currently processing this document" arrow>
              <CircularProgress size={16} />
            </Tooltip>
          </Box>
        )}

        <Tooltip arrow title="Download the document">
          <IconButton size="small" href={document.downloadUrl}>
            <Iconify icon="solar:download-minimalistic-bold" width={16} height={16} />
          </IconButton>
        </Tooltip>

        {hasScope('read:prompts') && (
          <Tooltip arrow title="View Document Debug Info">
            <IconButton size="small" onClick={() => infoDialog.onTrue()}>
              <Iconify icon="solar:minimalistic-magnifer-bug-bold" width={16} height={16} />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip arrow title="Delete this document">
          <IconButton size="small" onClick={() => deleteConfirm.onTrue()}>
            <Iconify icon="solar:trash-bin-trash-bold" width={16} height={16} />
          </IconButton>
        </Tooltip>
      </Stack>

      <ConfirmDialog
        open={deleteConfirm.value}
        onClose={deleteConfirm.onFalse}
        title="Delete"
        content={`Are you sure want to delete the document "${document.fileInfo.fileName}"?`}
        action={
          <LoadingButton
            loading={deleteProgress.value}
            variant="contained"
            color="error"
            onClick={() => handleDeleteDocument(document._id)}
          >
            Delete
          </LoadingButton>
        }
      />

      <Dialog open={infoDialog.value} onClose={infoDialog.onFalse} scroll="paper">
        <DialogTitle sx={{ pb: 2 }}>{document.fileInfo.fileName}</DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            <pre>{JSON.stringify(document, null, 2)}</pre>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

DocumentRow.propTypes = {
  userId: PropTypes.string,
  onDelete: PropTypes.func,
  document: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fileInfo: PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      filePath: PropTypes.string.isRequired,
      fileSize: PropTypes.number.isRequired,
      fileType: PropTypes.string.isRequired,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    textContent: PropTypes.string,
    downloadUrl: PropTypes.string,
    error: PropTypes.string,
    extractedInfo: PropTypes.shape({
      check: PropTypes.shape({
        value: PropTypes.bool,
      }),
      checkInfo: PropTypes.shape({
        value: PropTypes.string,
      }),
      summary: PropTypes.shape({
        value: PropTypes.string,
      }),
      fullName: PropTypes.shape({
        value: PropTypes.string,
      }),
      jobTitle: PropTypes.shape({
        value: PropTypes.string,
      }),
      interests: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      interestsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skills: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skillsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
};
