import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import Grid from '@mui/system/Unstable_Grid/Grid';
import {
  Card,
  Stack,
  Alert,
  Button,
  Divider,
  useTheme,
  CardHeader,
  CardContent,
  LinearProgress,
  CircularProgress,
} from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';
import { useProjects } from 'src/hooks/use-projects';
import { useUserById } from 'src/hooks/use-user-by-id';

import Iconify from 'src/components/iconify';
import ExampleProject from 'src/components/example-project';

// ----------------------------------------------------------------------

export default function OnboardingExamples({ cvDocument, percentage, next, prev }) {
  const api = useApi();
  const { data: user, mutate: mutateUser } = useUserById();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const loading = useBoolean(false);

  const onNext = async () => {
    if (user?.onboarding?.examples?.finished === true) {
      next();
      return;
    }

    loading.onTrue();

    try {
      const response = await api.patch(`/v1/users/${user._id}`, {
        source: 'onboarding',
        onboarding: {
          examples: {
            finished: true,
          },
        },
      });

      mutateUser(response.data);
      next();
    } catch (error) {
      enqueueSnackbar('Update failed!', { variant: 'error' });
      console.error(error);
    } finally {
      loading.onFalse();
    }
  };

  const { data: projects, isLoading: areProjectsLoading } = useProjects(
    user?._id,
    true,
    undefined,
    undefined,
    0,
    3,
    undefined,
    undefined,
    true
  );

  return (
    <Card sx={{ width: '480px', maxWidth: 'calc(100vw - 32px)', textAlign: 'left' }}>
      <CardHeader title="Projects Awaiting You" />
      <CardContent>
        These and many more projects are waiting for you! Explore a small selection tailored to your
        interests.
      </CardContent>

      <CardContent sx={{ backgroundColor: theme.palette.grey[200] }}>
        {areProjectsLoading && (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {!areProjectsLoading && projects?.data?.length === 0 && (
          <Alert severity="warning">
            Right now, there are no new projects that match your profile. Please proceed with the
            onboarding process, and we will notify you as soon as suitable projects become
            available!
          </Alert>
        )}

        {!areProjectsLoading && projects?.data?.length > 0 && (
          <Stack direction="column">
            {(projects?.data || []).map((item, index) => (
              <React.Fragment key={item._id}>
                {index > 0 && <Divider />}
                <ExampleProject
                  key={item._id}
                  user={user}
                  project={item}
                  index={index}
                  listCount={projects?.data?.length}
                />
              </React.Fragment>
            ))}
          </Stack>
        )}
      </CardContent>

      <CardContent>
        Some projects may be a perfect match, others less so. Share your experiences to only receive
        the best matches.
      </CardContent>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <CardContent>
        <Grid container sx={{ width: 1 }}>
          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<Iconify icon="eva:arrow-left-fill" />}
              onClick={prev}
            >
              Back
            </Button>
          </Grid>

          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <LinearProgress
              color="inherit"
              value={percentage}
              variant="determinate"
              sx={{ width: '60px' }}
            />
          </Grid>

          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <LoadingButton
              loading={loading.value}
              variant="contained"
              color="primary"
              endIcon={<Iconify icon="eva:arrow-right-fill" />}
              onClick={onNext}
            >
              Continue
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

OnboardingExamples.propTypes = {
  percentage: PropTypes.number,
  next: PropTypes.func,
  prev: PropTypes.func,
  cvDocument: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fileInfo: PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      filePath: PropTypes.string.isRequired,
      fileSize: PropTypes.number.isRequired,
      fileType: PropTypes.string.isRequired,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    textContent: PropTypes.string,
    downloadUrl: PropTypes.string,
    error: PropTypes.string,
    extractedInfo: PropTypes.shape({
      check: PropTypes.shape({
        value: PropTypes.bool,
      }),
      checkInfo: PropTypes.shape({
        value: PropTypes.string,
      }),
      summary: PropTypes.shape({
        value: PropTypes.string,
      }),
      fullName: PropTypes.shape({
        value: PropTypes.string,
      }),
      jobTitle: PropTypes.shape({
        value: PropTypes.string,
      }),
      interests: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      interestsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skills: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skillsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
};
