import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { useAuthState } from 'src/hooks/use-auth-state';

import { LoginView } from 'src/sections/login/view';

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { authenticated } = useAuthState();

  if (authenticated) {
    return <Navigate replace to={paths.dashboard.management.board()} />;
  }

  return (
    <>
      <Helmet>
        <title>projectfit.ai | Win freelance clients easily with the help of AI</title>
      </Helmet>

      <LoginView />
    </>
  );
}
