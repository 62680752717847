import axios from 'axios';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Card,
  Alert,
  Stack,
  Button,
  Divider,
  CardHeader,
  CardContent,
  LinearProgress,
  CircularProgress,
} from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';
import { useDocuments } from 'src/hooks/use-documents';
import { useUserById } from 'src/hooks/use-user-by-id';

import { cvFulfillsOnboarding } from 'src/utils/cv-fulfills-onboarding';

import animationData from 'src/assets/lotties/document-browsing.json';

import Iconify from 'src/components/iconify';
import { Upload, MultiFilePreview } from 'src/components/upload';
import OptionalTooltip from 'src/components/optional-tooltip/optional-tooltip';
import DocumentUploadStatus from 'src/components/document-upload-status/document-upload-status';

// ----------------------------------------------------------------------

export default function OnboardingDocument({ percentage, cvDocument, prev, next }) {
  const api = useApi();
  const { data: user, mutate: mutateUser } = useUserById();
  const { enqueueSnackbar } = useSnackbar();
  const inProgress = useBoolean(false);
  const [file, setFile] = useState(null);
  const { mutate: mutateDocuments } = useDocuments(user._id);
  const hasChanged = useBoolean();
  const loading = useBoolean();

  const [cvFulfilled, setCvFulfilled] = useState(true);

  const mappedDocument = useMemo(() => {
    if (!cvDocument) {
      return undefined;
    }

    return {
      id: cvDocument._id,
      key: cvDocument._id,
      name: cvDocument.fileInfo.fileName,
      path: cvDocument.fileInfo.fileName,
      preview: cvDocument._id,
      size: cvDocument.fileInfo.fileSize,
      type: cvDocument.fileInfo.fileType,
    };
  }, [cvDocument]);

  useEffect(() => {
    setCvFulfilled(cvFulfillsOnboarding(cvDocument, 'interests'));
  }, [cvDocument]);

  const deleteDocument = useCallback(async () => {
    if (!cvDocument) {
      return;
    }

    inProgress.onTrue();

    try {
      await api.delete(`/v1/documents/${cvDocument._id}`, {
        params: {
          userId: user._id,
        },
      });

      setFile(null);
      mutateDocuments();
      setCvFulfilled(true);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Could not delete document', { variant: 'error' });
    } finally {
      inProgress.onFalse();
    }
  }, [api, cvDocument, enqueueSnackbar, inProgress, mutateDocuments, user._id]);

  const uploadDocument = useCallback(
    async (uploadFile) => {
      inProgress.onTrue();
      hasChanged.onTrue();

      try {
        const preparation = await api.post(`/v1/documents`, {
          source: 'ONBOARDING',
          userId: user._id,
          type: 'CV',
          fileName: uploadFile.name,
          fileSize: uploadFile.size,
          fileType: uploadFile.type,
        });

        const { documentId, uploadUrl, downloadUrl } = preparation.data;

        if (!documentId || !uploadUrl || !downloadUrl) {
          console.error(preparation.data);
          throw new Error('Could not prepare document upload');
        }

        await axios.put(uploadUrl, uploadFile, {
          headers: {
            'Content-Type': uploadFile.file,
          },
        });

        await api.patch(`/v1/documents/${documentId}`, {
          userId: user._id,
          uploaded: true,
        });

        setCvFulfilled(false);
        mutateDocuments();
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Could not upload document', { variant: 'error' });
      } finally {
        inProgress.onFalse();
      }
    },
    [api, enqueueSnackbar, hasChanged, inProgress, mutateDocuments, user._id]
  );

  const handleDropSingleFile = useCallback(
    (files) => {
      if (files.length < 1) {
        return;
      }

      const fileExtension = files[0].name.split('.').pop().toLowerCase();

      if (fileExtension !== 'pdf') {
        enqueueSnackbar('Only PDF files are allowed', { variant: 'error' });
        return;
      }

      setFile(files[0]);
      uploadDocument(files[0]);
    },
    [enqueueSnackbar, uploadDocument]
  );

  const handleRemoveFile = useCallback(() => setFile(null), []);

  const onSubmit = async () => {
    if (!hasChanged.value && user?.onboarding?.document?.finished === true) {
      next();
      return;
    }

    loading.onTrue();

    try {
      const response = await api.patch(`/v1/users/${user._id}`, {
        source: 'onboarding',
        onboarding: {
          document: {
            finished: true,
          },
        },
      });

      mutateUser(response.data);
      next();
    } catch (error) {
      enqueueSnackbar('Update failed!', { variant: 'error' });
      console.error(error);
    } finally {
      loading.onFalse();
    }
  };

  const statusUploading = useMemo(() => {
    if (cvDocument?.status) {
      return 'success';
    }

    return inProgress.value === true ? 'processing' : 'success';
  }, [cvDocument?.status, inProgress.value]);

  const statusParsing = useMemo(() => {
    if (statusUploading === 'processing') {
      return 'pending';
    }

    if (cvDocument?.status === 'LOADED' || cvDocument?.textContent) {
      return 'success';
    }

    if (cvDocument?.status === 'FAILED') {
      return 'failed';
    }

    return 'processing';
  }, [cvDocument?.status, cvDocument?.textContent, statusUploading]);

  const statusExtracting = useMemo(() => {
    if (statusUploading === 'processing' || statusParsing === 'processing') {
      return 'pending';
    }

    if (cvDocument?.extractedInfo?.interests?.value || cvDocument?.status === 'PARSED') {
      return 'success';
    }

    if (cvDocument?.status === 'FAILED') {
      return 'failed';
    }

    return 'processing';
  }, [
    cvDocument?.extractedInfo?.interests?.value,
    cvDocument?.status,
    statusParsing,
    statusUploading,
  ]);

  return (
    <Card sx={{ width: '480px', maxWidth: 'calc(100vw - 32px)', textAlign: 'left' }}>
      <CardHeader title="Save Time. Upload your CV." />
      <CardContent>
        We value your time. Simply upload your CV here, and we&apos;ll extract all the necessary
        information for you. Of course you&apos;ll have the opportunity to make any corrections
        along the way!
      </CardContent>

      <CardContent sx={{ pt: 0 }}>
        {/* if we have already uploaded a CV or we already selected a document, we manually show the file preview */}
        {(cvDocument || file) && (
          <Box sx={{ width: 1, position: 'relative' }}>
            <Box sx={{ my: 0 }}>
              <MultiFilePreview
                icon={inProgress.value === true ? <CircularProgress size={16} /> : undefined}
                files={[file || mappedDocument]}
                thumbnail={false}
                onRemove={deleteDocument}
                sx={{ m: 0 }}
              />
            </Box>
          </Box>
        )}

        {/* if there is no uploaded CV and no file selected, show the drop zone */}
        {!cvDocument && !file && (
          <Upload
            accept={{ 'application/pdf': ['.pdf'] }}
            showIllustration={false}
            thumbnail={false}
            file={file}
            onDrop={handleDropSingleFile}
            onDelete={handleRemoveFile}
          />
        )}
      </CardContent>

      {(file || cvDocument) && (
        <CardContent sx={{ pt: 0 }}>
          <Grid container spacing={2}>
            <Grid xs={9} sm={8} sx={{ display: 'flex', alignItems: 'center' }}>
              <Stack direction="column" spacing={1}>
                <DocumentUploadStatus status={statusUploading} label="Uploading your document" />
                <DocumentUploadStatus status={statusParsing} label="Reading all pages" />
                <DocumentUploadStatus status={statusExtracting} label="Extract the information" />
              </Stack>
            </Grid>
            <Grid xs={3} sm={4}>
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                width="100%"
              />
            </Grid>

            {cvDocument?.status === 'FAILED' && (
              <Grid xs={12}>
                <Alert severity="error" sx={{ mt: 2 }}>
                  We could not process your CV: <b>{cvDocument.error}</b>. You can try a different
                  cv or continue without one.
                </Alert>
              </Grid>
            )}
          </Grid>
        </CardContent>
      )}

      <Divider sx={{ borderStyle: 'dashed' }} />

      <CardContent>
        <Grid container sx={{ width: 1 }}>
          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            {prev && (
              <Button
                variant="text"
                color="inherit"
                startIcon={<Iconify icon="eva:arrow-left-fill" />}
                onClick={prev}
              >
                Back
              </Button>
            )}
          </Grid>

          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <LinearProgress
              color="inherit"
              value={percentage}
              variant="determinate"
              sx={{ width: '60px' }}
            />
          </Grid>

          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <OptionalTooltip
              show={!cvFulfilled}
              title="We're currently processing your cv, this will take a few seconds"
            >
              <LoadingButton
                disabled={inProgress.value === true}
                loading={loading.value || inProgress.value || !cvFulfilled}
                variant="contained"
                color="primary"
                endIcon={<Iconify icon="eva:arrow-right-fill" />}
                onClick={onSubmit}
              >
                {cvDocument || file ? 'Continue' : 'Skip'}
              </LoadingButton>
            </OptionalTooltip>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

OnboardingDocument.propTypes = {
  prev: PropTypes.func,
  next: PropTypes.func,
  percentage: PropTypes.number,
  cvDocument: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fileInfo: PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      filePath: PropTypes.string.isRequired,
      fileSize: PropTypes.number.isRequired,
      fileType: PropTypes.string.isRequired,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    textContent: PropTypes.string,
    downloadUrl: PropTypes.string,
    error: PropTypes.string,
    extractedInfo: PropTypes.shape({
      check: PropTypes.shape({
        value: PropTypes.bool,
      }),
      checkInfo: PropTypes.shape({
        value: PropTypes.string,
      }),
      summary: PropTypes.shape({
        value: PropTypes.string,
      }),
      fullName: PropTypes.shape({
        value: PropTypes.string,
      }),
      jobTitle: PropTypes.shape({
        value: PropTypes.string,
      }),
      interests: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      interestsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skills: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skillsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
};
