import useSWR from 'swr';
import { useSnackbar } from 'notistack';

import { swrConfig } from 'src/hooks/swr.config';

import { fetcher } from 'src/utils/api-fetcher';

export function useSampleCoverLetters(userId) {
  const { enqueueSnackbar } = useSnackbar();

  let params = [`/v1/users/${userId}/sample-cover-letters`, enqueueSnackbar];

  if (!userId) {
    params = null;
  }

  const { data, error, isLoading, mutate } = useSWR(params, fetcher, swrConfig);

  return {
    data,
    error,
    isLoading,
    mutate,
  };
}
