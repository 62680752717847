import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useParams } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { useAuthState } from 'src/hooks/use-auth-state';

import ProjectGroupAnonView from 'src/pages/project-group-anon/project-group-anon-view';

// ----------------------------------------------------------------------

export default function ProjectGroupAnonPage() {
  const { authenticated } = useAuthState();
  const { groupId } = useParams();

  if (authenticated) {
    return <Navigate to={paths.dashboard.management.group(groupId)} />;
  }

  if (!groupId.match(/^[0-9a-fA-F]{24}$/)) {
    Sentry.captureException(new Error(`Invalid groupId: ${groupId}`));
    return <Navigate to={paths.page404} />;
  }

  return (
    <>
      <Helmet>
        <title>projectfit.ai | </title>
      </Helmet>

      <ProjectGroupAnonView />
    </>
  );
}
