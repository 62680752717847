import * as Yup from 'yup';
import { useState } from 'react';
import { m } from 'framer-motion';
import Lottie from 'react-lottie';
import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import { Box, Container } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import { Alert, CircularProgress } from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';
import { useAuthState } from 'src/hooks/use-auth-state';

import { bgGradient, textGradient } from 'src/theme/css';
import animationData from 'src/assets/lotties/check.json';

import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import InvitationCodeInput from 'src/components/invitation-code-input';

// ----------------------------------------------------------------------

const RegisterSchema = Yup.object().shape({
  code: Yup.string().min(6, 'invalid invite code').required('invite code is required'),
  newsletterAccepted: Yup.boolean(),
  emailAddress: Yup.string().email().required('email address is required'),
  password: Yup.string()
    .required('password is required')
    .min(8, 'password has to be at least 8 chars'),
  passwordRepeat: Yup.string()
    .required('password has to be repeated')
    .equals([Yup.ref('password')], 'passwords do not match'),
});

// ----------------------------------------------------------------------

const StyledTextGradient = styled(m.h1)(({ theme }) => ({
  ...textGradient(`300deg, #00A76F 0%, #007867 50%, #00A76F 100%`),
  padding: 0,
  marginTop: 0,
  marginBottom: 48,
  backgroundSize: '400%',
  lineHeight: 1.3,
  fontWeight: 900,
  letterSpacing: 0.7,
  fontSize: `3rem`,
  fontFamily: theme.typography.fontSecondaryFamily,
  [theme.breakpoints.up('sm')]: {
    fontSize: `4rem`,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: `5rem`,
  },
}));

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const api = useApi(false);
  const theme = useTheme();
  const isSuccess = useBoolean(false);
  const [searchParams] = useSearchParams();
  const [validityCheck, setValidityCheck] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { login } = useAuthState();

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues: {
      newsletterAccepted: false,
      code: searchParams.get('code') || '',
      emailAddress: searchParams.has('email')
        ? decodeURIComponent(searchParams.get('email') || '')
        : '',
      password: '',
      passwordRepeat: '',
    },
  });

  const triggerLogin = async (emailAddress, password) => {
    try {
      await Promise.all([
        new Promise((resolve) => setTimeout(resolve, 2500)),
        login(emailAddress, password),
      ]);

      window.location.href = '/onboarding';
    } catch (err) {
      Sentry.captureException(err);
      window.location.href = '/login';
    }
  };

  const onSubmit = methods.handleSubmit(async (data) => {
    isSuccess.onFalse();
    setErrorMessage(null);

    try {
      await api.post('/v1/user-invitation', {
        newsletterAccepted: data.newsletterAccepted,
        invitationCode: data.code,
        emailAddress: data.emailAddress,
        password: data.password,
      });

      isSuccess.onTrue();

      await triggerLogin(data.emailAddress, data.password);
    } catch (error) {
      const message = error?.response?.data?.error || 'An error occurred. Please try again later.';
      setErrorMessage(message);
    }
  });

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          opacity: 0.24,
          ...bgGradient({
            direction: '180deg',
            startColor: alpha(theme.palette.grey[900], 0),
            endColor: alpha(theme.palette.grey[900], 0.1),
          }),
        }}
      />

      <Grid
        container
        columnSpacing={{ xs: 0, md: 10 }}
        sx={{
          minHeight: { md: '65vh' },
          py: { xs: 10, md: 10 },
          overflow: 'hidden',
        }}
      >
        <Grid xs={12} md={12}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ height: 1, mx: 'auto', maxWidth: 800, textAlign: 'center' }}
          >
            <Typography
              variant="h6"
              sx={{ textTransform: 'uppercase', color: theme.palette.grey[600] }}
            >
              Start now with projectfit.ai
            </Typography>

            <StyledTextGradient
              animate={{ backgroundPosition: '200% center' }}
              transition={{
                repeatType: 'reverse',
                ease: 'linear',
                duration: 20,
                repeat: Infinity,
              }}
            >
              Sign up
            </StyledTextGradient>

            <FormProvider methods={methods} onSubmit={onSubmit} sx={{ position: 'relative' }}>
              <Box sx={{ position: 'relative' }}>
                <Stack
                  spacing={2}
                  direction="column"
                  sx={{
                    width: '350px',
                    maxWidth: '100%',
                    visibility: isSuccess.value === true ? 'hidden' : 'visible',
                  }}
                >
                  <InvitationCodeInput
                    watchCode={methods.watch('code')}
                    onValidityCheck={setValidityCheck}
                    disabled={
                      searchParams.get('code')?.length > 0 &&
                      (!validityCheck || validityCheck?.isValid === true)
                    }
                  />

                  <RHFTextField
                    name="emailAddress"
                    label="Email Address"
                    inputProps={{
                      sx: { backgroundColor: 'white', borderRadius: '8px' },
                    }}
                  />

                  <RHFTextField
                    type="password"
                    name="password"
                    label="Password"
                    inputProps={{
                      sx: { backgroundColor: 'white', borderRadius: '8px' },
                    }}
                  />

                  <RHFTextField
                    type="password"
                    name="passwordRepeat"
                    label="Password (repeat)"
                    inputProps={{
                      sx: { backgroundColor: 'white', borderRadius: '8px' },
                    }}
                  />

                  {errorMessage && (
                    <Alert severity="error" sx={{ width: 1 }}>
                      {errorMessage}
                    </Alert>
                  )}

                  <RHFCheckbox
                    name="newsletterAccepted"
                    label="I want to receive the newest updates by email"
                  />

                  <LoadingButton
                    type="submit"
                    loading={methods.formState.isSubmitting}
                    color="primary"
                    size="large"
                    variant="contained"
                    startIcon={<Iconify icon="solar:rocket-2-bold" width={24} />}
                  >
                    Activate AI Assistant NOW
                  </LoadingButton>
                </Stack>

                {isSuccess.value === true && (
                  <Box
                    sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  >
                    <Stack spacing={1.5} direction="column" sx={{ minWidth: '320px' }}>
                      <Lottie
                        options={{
                          loop: false,
                          autoplay: true,
                          animationData,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        width={196}
                        height={196}
                      />
                      <Stack
                        spacing={1.5}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <CircularProgress size={24} />
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: 'center', fontWeight: 'normal' }}
                        >
                          You will be redirected in a few seconds!
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                )}
              </Box>
            </FormProvider>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
