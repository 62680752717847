import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import { RouterLink } from 'src/routes/components';

import { useAuthState } from 'src/hooks/use-auth-state';

// ----------------------------------------------------------------------

export default function LoginButton({ label = 'Login', sx }) {
  const { authenticated } = useAuthState();

  if (authenticated) {
    return (
      <Button component={RouterLink} href="/dashboard" variant="outlined" sx={{ mr: 1, ...sx }}>
        {label}
      </Button>
    );
  }

  return (
    <Button component={RouterLink} href="/login" variant="outlined" sx={{ mr: 1, ...sx }}>
      {label}
    </Button>
  );
}

LoginButton.propTypes = {
  label: PropTypes.string,
  sx: PropTypes.object,
};
