import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { Stack } from '@mui/system';
import { Tooltip, CircularProgress } from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

function daysToReadableForm(days) {
  if (days < 7) return `${days} day${days > 1 ? 's' : ''}`;
  if (days < 31) return `${Math.floor(days / 7)} week${Math.floor(days / 7) > 1 ? 's' : ''}`;
  if (days < 365) return `${Math.floor(days / 30)} month${Math.floor(days / 30) > 1 ? 's' : ''}`;
  return `${Math.floor(days / 365)} year${Math.floor(days / 365) > 1 ? 's' : ''}`;
}

export default function InvitationCodeInput({ watchCode, onValidityCheck, ...other }) {
  const api = useApi(false);
  const theme = useTheme();
  const isLoading = useBoolean(false);
  const [codeRequesting, setCodeRequesting] = useState(null);
  const [validityCheck, setValidityCheck] = useState(null);

  useEffect(() => {
    onValidityCheck(validityCheck);
  }, [onValidityCheck, validityCheck]);

  const checkValidity = useCallback(
    async (code) => {
      if (code === validityCheck?.code || code === codeRequesting) {
        return;
      }

      if (code.length === 0) {
        setValidityCheck(null);
        isLoading.onFalse();
        return;
      }

      isLoading.onTrue();
      setCodeRequesting(code);

      try {
        const result = await api.get(`/v1/invitation-codes?code=${code}`);

        setValidityCheck({
          code,
          available: result.data?.available,
          isValid: result.data?.isValid,
          isExhausted: result.data?.isExhausted,
          trialPeriodDays: result.data?.trialPeriodDays,
          percentOff: result.data?.discountCode?.percentOff,
        });
      } catch (error) {
        setValidityCheck({
          code,
          available: false,
        });
      } finally {
        isLoading.onFalse();
      }
    },
    [validityCheck?.code, codeRequesting, isLoading, api]
  );

  useEffect(() => {
    if (!watchCode) {
      setValidityCheck(null);
      return () => {};
    }

    if (watchCode === validityCheck?.code) {
      return () => {};
    }

    isLoading.onTrue();

    // debounce for 1s and execute the callback
    const debounce = setTimeout(() => {
      checkValidity(watchCode);
    }, 1000);

    return () => clearTimeout(debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCode, validityCheck?.code, codeRequesting]);

  useEffect(() => {
    checkValidity(watchCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validityCheckInfo = useMemo(() => {
    if (validityCheck?.trialPeriodDays && validityCheck?.percentOff) {
      const trialPeriod = daysToReadableForm(validityCheck.trialPeriodDays);

      return {
        tooltip: `This code will unlock ${trialPeriod} of premium and a discount of ${validityCheck.percentOff}% afterwards!`,
        message: [`${trialPeriod} free`, `AND ${validityCheck.percentOff}% off`],
      };
    }

    if (validityCheck?.trialPeriodDays) {
      const trialPeriod = daysToReadableForm(validityCheck.trialPeriodDays);

      return {
        tooltip: `This code will unlock ${trialPeriod} of premium!`,
        message: `${trialPeriod} free`,
      };
    }

    if (validityCheck?.percentOff) {
      return {
        tooltip: `This code will unlock a discount of ${validityCheck.percentOff}% after the trial period!`,
        message: `-${validityCheck.percentOff}% Discount`,
      };
    }

    return undefined;
  }, [validityCheck]);

  const inputIcon = useMemo(() => {
    if (isLoading.value) {
      return <CircularProgress color="inherit" size={20} />;
    }

    if (validityCheck?.available === true) {
      const icon = (
        <Iconify icon="eva:checkmark-circle-2-fill" color={theme.palette.primary.main} width={28} />
      );

      return (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ flexShrink: 0 }}>
          {validityCheckInfo && (
            <Tooltip title={validityCheckInfo.tooltip} arrow>
              {Array.isArray(validityCheckInfo.message) ? (
                <Label
                  color="success"
                  sx={{ textTransform: 'uppercase', lineHeight: '16px', height: 'auto', py: '3px' }}
                >
                  <Stack direction="column">
                    {validityCheckInfo.message.map((message, index) => (
                      <span key={index}>{message}</span>
                    ))}
                  </Stack>
                </Label>
              ) : (
                <Label color="success" sx={{ textTransform: 'uppercase' }}>
                  {validityCheckInfo.message}
                </Label>
              )}
            </Tooltip>
          )}

          {icon}
        </Stack>
      );
    }

    if (
      validityCheck?.available === false &&
      (validityCheck?.isValid === false || validityCheck?.isExhausted === true)
    ) {
      return (
        <Tooltip title="This invitation code is no longer available" arrow>
          <Iconify icon="eva:close-circle-fill" color={theme.palette.error.main} width={28} />
        </Tooltip>
      );
    }

    if (validityCheck?.available === false) {
      return (
        <Tooltip title="This invitation code is invalid" arrow>
          <Iconify icon="eva:close-circle-fill" color={theme.palette.error.main} width={28} />
        </Tooltip>
      );
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading.value, validityCheck]);

  return (
    <RHFTextField
      name="code"
      label="Invitation Code"
      InputProps={{
        sx: { backgroundColor: 'white', borderRadius: '8px' },
        endAdornment: inputIcon,
      }}
      {...other}
    />
  );
}

InvitationCodeInput.propTypes = {
  watchCode: PropTypes.string,
  onValidityCheck: PropTypes.func,
};
