import useSWR from 'swr';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { swrConfig } from 'src/hooks/swr.config';

import { fetcher } from 'src/utils/api-fetcher';

export function useLlmBenchmark(id) {
  const { enqueueSnackbar } = useSnackbar();

  let params = [`/v1/llm-benchmarks/${id}`, enqueueSnackbar];

  if (!id) {
    params = null;
  }

  const { data, error, isLoading, mutate } = useSWR(params, fetcher, swrConfig);

  const hasProcessingData = ['CREATED', 'PROCESSING', 'CONFIRMED'].includes(data?.status);

  useEffect(() => {
    if (hasProcessingData) {
      const timeout = setInterval(() => {
        mutate();
      }, 5000);

      return () => clearInterval(timeout);
    }

    return () => {};
  }, [hasProcessingData, mutate]);

  return {
    data,
    error,
    isLoading,
    mutate,
  };
}
