import { useUserById } from 'src/hooks/use-user-by-id';
import { useAuthState } from 'src/hooks/use-auth-state';

import { LoadingScreen } from 'src/components/loading-screen';

import OnboardingPage from 'src/sections/onboarding/onboarding';

// ----------------------------------------------------------------------

export default function Onboarding() {
  const { userId: authUserId } = useAuthState();
  const { data: user, isLoading } = useUserById();

  if (!authUserId) {
    return <LoadingScreen />;
  }

  if (isLoading || !user?._id) {
    return <LoadingScreen />;
  }

  return <OnboardingPage />;
}
