import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useAuthState } from 'src/hooks/use-auth-state';

// ----------------------------------------------------------------------

const loginPaths = {
  general: paths.login,
};

// ----------------------------------------------------------------------

export default function AuthGuard({ children, redirectTarget }) {
  return <Container redirectTarget={redirectTarget}>{children}</Container>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
  redirectTarget: PropTypes.func,
};

// ----------------------------------------------------------------------

function Container({ children, redirectTarget }) {
  const { authenticated } = useAuthState();
  const router = useRouter();
  const params = useParams();
  const location = useLocation();
  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      const overwrittenTarget = redirectTarget
        ? redirectTarget(location.pathname, params)
        : undefined;

      if (overwrittenTarget) {
        router.replace(overwrittenTarget);
        return;
      }

      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      const loginPath = loginPaths.general;
      const href = `${loginPath}?${searchParams}`;

      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [authenticated, location, params, redirectTarget, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

Container.propTypes = {
  children: PropTypes.node,
  redirectTarget: PropTypes.func,
};
