import PropTypes from 'prop-types';

import { Alert, alpha, useTheme } from '@mui/material';

import { useLocalStorage } from 'src/hooks/use-local-storage';
import { useSampleCoverLetters } from 'src/hooks/use-sample-cover-letters';

import { bgGradient } from 'src/theme/css';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'cover-letter-not-personal-info';

// ----------------------------------------------------------------------

export default function GroupApplicationCoverLetterContentPersonality({ index, user }) {
  const { state: hideInfo, update } = useLocalStorage(STORAGE_KEY, false);

  const theme = useTheme();
  const { data: sampleCoverLetters, isLoading } = useSampleCoverLetters(user?._id);

  if (hideInfo || isLoading || index !== 0 || sampleCoverLetters?.data?.length >= 1) {
    return null;
  }

  return (
    <Alert
      severity="info"
      onClose={() => {
        update(true);
      }}
      closeText="Do not show this message again"
      sx={{
        mt: 1,
        ...bgGradient({
          direction: '45deg',
          startColor: alpha(theme.palette.info.light, 0.2),
          endColor: alpha(theme.palette.info.main, 0.25),
        }),
      }}
    >
      <b>Not personal enough?</b> Upload examples of your previous applications to better influence
      and customize the generated content.
    </Alert>
  );
}

GroupApplicationCoverLetterContentPersonality.propTypes = {
  user: PropTypes.object,
  index: PropTypes.number,
};
