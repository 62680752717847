import * as Sentry from '@sentry/react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApi } from 'src/hooks/use-api';

export const LS_SESSION_STATE_KEY = 'auth-session-state';

export function useAuthState() {
  const api = useApi();
  const navigate = useNavigate();

  const getLocalStorageValue = () => {
    let localStorageValue = {};

    try {
      localStorageValue = localStorage.getItem(LS_SESSION_STATE_KEY)
        ? JSON.parse(localStorage.getItem(LS_SESSION_STATE_KEY))
        : {};
    } catch (error) {
      Sentry.captureException(new Error('Failed to parse auth session state from localStorage'));
    }

    if (localStorageValue?.sessionExpiresAt) {
      // check if the session is still valid
      const sessionExpiresAt = new Date(localStorageValue.sessionExpiresAt);
      const now = new Date();

      if (sessionExpiresAt < now) {
        localStorage.removeItem(LS_SESSION_STATE_KEY);
        localStorageValue = {};
      }
    }

    return localStorageValue;
  };

  const initialLocalStorageValue = useMemo(() => getLocalStorageValue(), []);
  const [authSession, setAuthSession] = useState(initialLocalStorageValue);

  const login = async (username, password) => {
    const response = await api.post('/v1/auth/login', {
      username,
      password,
    });

    if (response.data?.status !== 'success') {
      throw new Error('An error occurred. Please try again later.');
    }

    localStorage.setItem(
      LS_SESSION_STATE_KEY,
      JSON.stringify({
        loggedIn: true,
        userId: response.data.userId,
        scopes: response.data.scopes,
        sessionExpiresAt: response.data.sessionExpiresAt,
      })
    );

    setAuthSession({
      loggedIn: true,
      userId: response.data.userId,
      scopes: response.data.scopes,
      sessionExpiresAt: response.data.sessionExpiresAt,
    });
  };

  const logout = async () => {
    try {
      await api.post('/v1/auth/logout');
    } catch (error) {
      // do not show error to user
      Sentry.captureException(error);
    }

    localStorage.removeItem(LS_SESSION_STATE_KEY);
    setAuthSession({});
    navigate('/');
  };

  return {
    authenticated: authSession.loggedIn || false,
    userId: authSession.userId || null,
    scopes: authSession.scopes || [],
    sessionExpiresAt: authSession.sessionExpiresAt || null,
    login,
    logout,
  };
}
