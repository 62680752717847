import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useMemo, useState, useEffect } from 'react';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/system/Unstable_Grid/Grid';
import {
  Card,
  Alert,
  Button,
  Divider,
  CardHeader,
  CardContent,
  ToggleButton,
  ListItemText,
  LinearProgress,
} from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';
import { useUserById } from 'src/hooks/use-user-by-id';

import { cvFulfillsOnboarding } from 'src/utils/cv-fulfills-onboarding';
import { E_MAIL_NOTIFICATION_OPTIONS } from 'src/utils/email-notification-options';

import Iconify from 'src/components/iconify';
import OptionalTooltip from 'src/components/optional-tooltip/optional-tooltip';

// ----------------------------------------------------------------------

export default function OnboardingNotifications({ cvDocument, percentage, next, prev }) {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { data: user, mutate: mutateUser } = useUserById();
  const [selected, setSelected] = useState([]);
  const hasChanged = useBoolean();

  const cvFulfilled = useMemo(() => cvFulfillsOnboarding(cvDocument, 'skills'), [cvDocument]);

  const allNotificationLevels = useMemo(
    () =>
      (user?.notifications || [])
        .map((item) => `${item.email}`)
        .filter((value, index, self) => self.indexOf(value) === index),
    [user?.notifications]
  );

  useEffect(() => {
    if (allNotificationLevels.length === 0) {
      setSelected(['66']);
    } else {
      setSelected(allNotificationLevels);
    }
  }, [allNotificationLevels]);

  const loading = useBoolean(false);

  const toggleSetting = (setting) => {
    if (selected.length === 1 && selected[0] === setting) {
      return;
    }

    hasChanged.onTrue();
    setSelected([setting]);
  };

  const onSubmit = async () => {
    if (!hasChanged.value && user?.onboarding?.notifications?.finished === true) {
      next();
      return;
    }

    loading.onTrue();

    const notifications = (user?.subscriptions || [])
      .map((skillId) => ({
        skillId,
        email: selected[0] || '-1',
      }))
      .filter((v) => v.email !== '-1');

    try {
      const response = await api.patch(`/v1/users/${user._id}`, {
        source: 'onboarding',
        notifications,
        onboarding: {
          notifications: {
            finished: true,
          },
        },
      });
      mutateUser(response.data);
      next();
    } catch (error) {
      enqueueSnackbar('Update failed!', { variant: 'error' });
      console.error(error);
    } finally {
      loading.onFalse();
    }
  };

  return (
    <Card sx={{ width: '480px', maxWidth: 'calc(100vw - 32px)', textAlign: 'left' }}>
      <CardHeader title="When should we notify you?" />
      <CardContent>
        When we match projects against your profile, we categorize them into four distinct levels –
        based on relevance to your experiences. You can set the minimum level that triggers a
        notification.
        {selected.length > 1 && (
          <Alert severity="warning" sx={{ width: 1, mt: 2 }}>
            You already configured different notifications for different areas. If you pick one
            option here, you will overwrite the notification setting for all areas.
          </Alert>
        )}
        {selected.length <= 1 && allNotificationLevels.length === 0 && (
          <Alert severity="info" sx={{ width: 1, mt: 2 }}>
            For the beginning, we recommend to start with &quot;<b>At least: Good Match</b>
            &quot;. This way, you can get a better sense of the types and frequency of projects that
            come through, helping you fine-tune your preferences as you go.
          </Alert>
        )}
      </CardContent>

      <CardContent sx={{ pt: 0 }}>
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          {E_MAIL_NOTIFICATION_OPTIONS.map((option) => (
            <ToggleButton
              key={option.label}
              size="medium"
              color="primary"
              value="check"
              selected={selected.includes(option.value)}
              onChange={() => toggleSetting(option.value)}
              sx={{ width: 1 }}
            >
              <Iconify
                icon="eva:checkmark-fill"
                sx={{ visibility: selected.includes(option.value) ? undefined : 'hidden' }}
              />

              <ListItemText
                disableTypography
                primary={option.label}
                sx={{
                  ml: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  textAlign: 'left',
                }}
              />
            </ToggleButton>
          ))}
        </Stack>
      </CardContent>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <CardContent>
        <Grid container sx={{ width: 1 }}>
          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<Iconify icon="eva:arrow-left-fill" />}
              onClick={prev}
            >
              Back
            </Button>
          </Grid>

          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <LinearProgress
              color="inherit"
              value={percentage}
              variant="determinate"
              sx={{ width: '60px' }}
            />
          </Grid>

          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <OptionalTooltip
              show={!cvFulfilled}
              title="We're currently processing your cv, this will take a few seconds"
            >
              <LoadingButton
                loading={loading.value || !cvFulfilled}
                disabled={selected.length === 0}
                variant="contained"
                color="primary"
                endIcon={<Iconify icon="eva:arrow-right-fill" />}
                onClick={onSubmit}
              >
                Continue
              </LoadingButton>
            </OptionalTooltip>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

OnboardingNotifications.propTypes = {
  percentage: PropTypes.number,
  next: PropTypes.func,
  prev: PropTypes.func,
  cvDocument: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fileInfo: PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      filePath: PropTypes.string.isRequired,
      fileSize: PropTypes.number.isRequired,
      fileType: PropTypes.string.isRequired,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    textContent: PropTypes.string,
    downloadUrl: PropTypes.string,
    error: PropTypes.string,
    extractedInfo: PropTypes.shape({
      check: PropTypes.shape({
        value: PropTypes.bool,
      }),
      checkInfo: PropTypes.shape({
        value: PropTypes.string,
      }),
      summary: PropTypes.shape({
        value: PropTypes.string,
      }),
      fullName: PropTypes.shape({
        value: PropTypes.string,
      }),
      jobTitle: PropTypes.shape({
        value: PropTypes.string,
      }),
      interests: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      interestsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skills: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skillsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
};
