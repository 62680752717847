import PropTypes from 'prop-types';

import { Card, Stack } from '@mui/material';

import GroupInfoMatchHeader from 'src/pages/dashboard/management/group/info/match-header';
import GroupInfoMissingSkills from 'src/pages/dashboard/management/group/info/missing-skills';
import GroupInfoRobertAnalysis from 'src/pages/dashboard/management/group/info/robert-analysis';

// ----------------------------------------------------------------------

export default function GroupInfoMatchInfo({ item, user }) {
  const hasFinishedMatching = item.userDetails?.matching?.status === 'FINISHED';

  return (
    <Card>
      <Stack direction="column" spacing={3} sx={{ pb: hasFinishedMatching ? 2 : 0 }}>
        {/* Matching header incl. user opinion switch */}
        <GroupInfoMatchHeader item={item} user={user} />

        {/* Robert Analysis */}
        <GroupInfoRobertAnalysis
          personalAnalysis={item?.userDetails?.matching?.result?.personalAnalysis}
        />

        {/* Missing Skills */}
        <GroupInfoMissingSkills
          missingSkills={item.userDetails?.matching?.result?.missingSkills || []}
          userSkills={user.skills || []}
          userId={user._id}
        />
      </Stack>
    </Card>
  );
}

GroupInfoMatchInfo.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    postingCount: PropTypes.number,
    postings: PropTypes.arrayOf(
      PropTypes.shape({
        projectId: PropTypes.string,
        postedAt: PropTypes.string,
        platform: PropTypes.string,
        sourceLink: PropTypes.string,
        status: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        details: PropTypes.shape({
          descriptionLanguage: PropTypes.string,
          authorCompany: PropTypes.string,
          authorName: PropTypes.string,
          hourlyRate: PropTypes.number,
          startDate: PropTypes.string,
          workload: PropTypes.string,
          durationInMonths: PropTypes.number,
          industry: PropTypes.string,
          contractType: PropTypes.string,
          workplace: PropTypes.string,
          location: PropTypes.string,
        }),
      })
    ),
    details: PropTypes.shape({
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
    skills: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
      references: PropTypes.arrayOf(PropTypes.string),
    }),
    userDetails: PropTypes.shape({
      opinion: PropTypes.shape({
        projectId: PropTypes.string,
        comment: PropTypes.string,
        level: PropTypes.number,
        createdAt: PropTypes.string,
      }),
      matching: PropTypes.shape({
        projectId: PropTypes.string,
        createdAt: PropTypes.string,
        status: PropTypes.string,
        result: PropTypes.shape({
          information: PropTypes.string,
          level: PropTypes.number,
          missingSkills: PropTypes.arrayOf(PropTypes.string),
          missingSkillsReferences: PropTypes.arrayOf(PropTypes.string),
          personalAnalysis: PropTypes.string,
        }),
      }),
      applicationCoverLetters: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          projectId: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          createdAt: PropTypes.string,
        })
      ),
    }),
  }),
  user: PropTypes.object,
};
