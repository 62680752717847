import PropTypes from 'prop-types';
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose';

import { Box, Stack } from '@mui/system';
import ListItemText from '@mui/material/ListItemText';
import { Link, Typography, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { fCurrency } from 'src/utils/format-number';
import { fTime, fDate } from 'src/utils/format-time';
import platformLabel from 'src/utils/platform-label';

import Label from 'src/components/label';

// ----------------------------------------------------------------------

export function RenderUnreadBadge({ params }) {
  if (params.row.status === 'UNREAD') {
    return (
      <Box
        sx={{
          width: 8,
          height: 8,
          mr: 2,
          borderRadius: '50%',
          bgcolor: 'info.main',
          flexShrink: 0,
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        width: 8,
        height: 8,
        mr: 2,
        borderRadius: '50%',
        bgcolor: 'transparent',
        flexShrink: 0,
      }}
    />
  );
}

RenderUnreadBadge.propTypes = {
  params: PropTypes.shape({
    row: PropTypes.object,
    field: PropTypes.string,
  }),
};

export function RenderCellBadge({
  params,
  color = 'default',
  showSpinner = false,
  fullWidth = false,
}) {
  if (!params.row[params.field]) {
    return null;
  }

  return (
    <Label
      variant="soft"
      color={color}
      sx={{
        width: fullWidth ? 1 : undefined,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {params.row[params.field]}
      {showSpinner && <CircularProgress size={14} />}
    </Label>
  );
}

RenderCellBadge.propTypes = {
  params: PropTypes.shape({
    row: PropTypes.object,
    field: PropTypes.string,
  }),
  color: PropTypes.string,
  showSpinner: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export function RenderCellPrice({ params }) {
  if (params.row[params.field] !== 0 && !params.row[params.field]) {
    return null;
  }

  return (
    <Typography variant="body2" noWrap>
      {fCurrency(params.row[params.field])}
    </Typography>
  );
}

RenderCellPrice.propTypes = {
  params: PropTypes.shape({
    row: PropTypes.object,
    field: PropTypes.string,
  }),
};

export function RenderCellEllipsis({ params }) {
  return (
    <span title={params.row[params.field]}>
      <LinesEllipsisLoose text={params.row[params.field]} maxLine="1" lineHeight="16" />
    </span>
  );
}

RenderCellEllipsis.propTypes = {
  params: PropTypes.shape({
    row: PropTypes.object,
    field: PropTypes.string,
  }),
};

export function RenderCellDate({ params }) {
  return (
    <ListItemText
      primary={fDate(params.row[params.field])}
      secondary={fTime(params.row[params.field])}
      primaryTypographyProps={{
        typography: 'body2',
        noWrap: true,
        color: params.row.status === 'UNREAD' ? 'text.primary' : 'text.secondary',
      }}
      secondaryTypographyProps={{
        mt: 0.5,
        component: 'span',
        typography: 'caption',
      }}
    />
  );
}

RenderCellDate.propTypes = {
  params: PropTypes.shape({
    field: PropTypes.string,
    row: PropTypes.object,
  }),
};

export function RenderCellProject({ params, userId, onClick }) {
  return (
    <Stack direction="row" alignItems="center" sx={{ py: 2, width: 1 }}>
      <RenderUnreadBadge params={params} />

      <ListItemText
        disableTypography
        primary={
          <Link
            onClick={onClick}
            component={RouterLink}
            color={params.row.status === 'UNREAD' ? 'text.primary' : 'text.secondary'}
            variant="subtitle2"
            href={paths.dashboard.management.projectDetail(userId, params.row.projectId)}
            sx={{
              cursor: 'pointer',
              overflow: 'auto',
              fontWeight: params.row.status === 'UNREAD' ? 600 : 'normal',
            }}
          >
            <span title={params.row.title}>
              <LinesEllipsisLoose text={params.row.title} maxLine="1" lineHeight="16" />
            </span>
          </Link>
        }
        secondary={
          <Box component="div" sx={{ typography: 'body2', color: 'text.disabled' }}>
            {platformLabel(params.row.platform)}
          </Box>
        }
        sx={{ display: 'flex', flexDirection: 'column' }}
      />
    </Stack>
  );
}

RenderCellProject.propTypes = {
  params: PropTypes.object,
  userId: PropTypes.string,
  onClick: PropTypes.func,
};
