import useSWR from 'swr';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { swrConfig } from 'src/hooks/swr.config';

import { fetcher } from 'src/utils/api-fetcher';

export function useProjects(
  userId,
  onlySubscribed,
  matchLevel,
  searchTerm,
  page,
  pageSize,
  sort,
  order,
  preventUserDetails
) {
  const { enqueueSnackbar } = useSnackbar();

  let params = [
    `/v1/projects`,
    enqueueSnackbar,
    {
      params: {
        ...(typeof page !== 'undefined' && typeof pageSize !== 'undefined'
          ? { from: page * pageSize, limit: pageSize }
          : {}),
        ...(typeof sort !== 'undefined' && typeof order !== 'undefined' ? { sort, order } : {}),
        ...(matchLevel && matchLevel.length > 0 ? { matchLevel: matchLevel.join(',') } : {}),
        ...(searchTerm ? { q: searchTerm } : {}),
        onlySubscribed: onlySubscribed ? 'true' : 'false',
        preventUserDetails: preventUserDetails ? 'true' : 'false',
        userId,
      },
    },
  ];

  if (!userId) {
    params = null;
  }

  const { data, error, isLoading, mutate } = useSWR(params, fetcher, swrConfig);

  const hasProcessingData = (data || []).data?.some(
    (project) =>
      project.status !== 'PARSED' ||
      project.matches?.[0]?.status === 'PROCESSING' ||
      project.matches?.[0]?.status === 'INITIALIZED'
  );

  useEffect(() => {
    if (hasProcessingData) {
      const timeout = setInterval(() => {
        mutate();
      }, 5000);

      return () => clearInterval(timeout);
    }

    return () => {};
  }, [hasProcessingData, mutate]);

  return {
    data,
    error,
    isLoading,
    mutate,
  };
}
