import { useAuthState } from 'src/hooks/use-auth-state';

export function useScopes() {
  const { scopes } = useAuthState();

  const hasOneOfScopes = (requiredScopes) =>
    requiredScopes.some((requiredScope) => (scopes || []).includes(requiredScope));

  const hasAllScopes = (requiredScopes) =>
    requiredScopes.every((requiredScope) => (scopes || []).includes(requiredScope));

  const hasScope = (requiredScope) => (scopes || []).includes(requiredScope);

  return { scopes, hasOneOfScopes, hasAllScopes, hasScope };
}
