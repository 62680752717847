/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------
export default function GuestGuard({ children }) {
  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.node,
};
