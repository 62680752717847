import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useSearchParams } from 'react-router-dom';

import { Stack, Tooltip } from '@mui/material';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';

import { useUserById } from 'src/hooks/use-user-by-id';
import { useAuthState } from 'src/hooks/use-auth-state';
import { useProjectGroup } from 'src/hooks/use-project-group';

import { canSeeProjectApply } from 'src/utils/access-helper';

import GroupTabs from 'src/pages/dashboard/management/group/tabs';
import GroupInfoContent from 'src/pages/dashboard/management/group/info-content';
import GroupApplyContent from 'src/pages/dashboard/management/group/apply-content';
import GroupAggregatedSkills from 'src/pages/dashboard/management/group/aggregated-skills';

import Iconify from 'src/components/iconify';
import ActionBar from 'src/components/action-bar';
import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

// ----------------------------------------------------------------------

const TABS_DEFINITION = [
  {
    value: 'info',
    label: () => 'Information',
    icon: <Iconify icon="solar:bill-list-bold" width={24} />,
    element: GroupInfoContent,
    canAccess: () => true,
  },
  {
    value: 'apply',
    label: (theme, { hasApplied }) => (
      <Stack direction="row" alignItems="center" spacing={1}>
        Application
        {hasApplied && (
          <Tooltip arrow top title="Cover Letter has been generated">
            <Iconify
              icon="eva:checkmark-circle-2-fill"
              color={theme.palette.primary.main}
              width={16}
              height={16}
            />
          </Tooltip>
        )}
      </Stack>
    ),
    icon: <Iconify icon="solar:letter-opened-bold" width={24} />,
    element: GroupApplyContent,
    canAccess: (hasScope) => canSeeProjectApply(hasScope),
  },
];

const DEFAULT_TAB = 'info';

// ----------------------------------------------------------------------

export default function ProjectGroupPage() {
  const settings = useSettingsContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams?.get('tab') || DEFAULT_TAB;

  const handleChangeTab = useCallback(
    (_, newValue) => {
      setSearchParams({ tab: newValue });
    },
    [setSearchParams]
  );

  const { userId: authUserId } = useAuthState();
  const { id: groupId, userId } = useParams();

  const { data: user } = useUserById(userId);
  const { data: group, isLoading } = useProjectGroup(groupId, user?._id, true);

  if (isLoading || !group?._id) {
    return <LoadingScreen title="projectfit.ai | Project" />;
  }

  const DisplayComponent =
    TABS_DEFINITION.find((tab) => tab.value === currentTab)?.element || TABS_DEFINITION[0].element;

  return (
    <>
      <Helmet>
        <title>projectfit.ai | Project</title>
      </Helmet>

      <Container
        key={`${group._id}-${currentTab}`}
        maxWidth={settings.themeStretch ? false : 'lg'}
        sx={{ display: 'flex', flexDirection: 'column', height: 1, flexGrow: 1 }}
      >
        {/* HEADING */}
        <CustomBreadcrumbs
          heading={group.title}
          links={[
            {
              name: 'Projects',
              href: paths.dashboard.management.board(),
            },
            userId !== authUserId
              ? {
                  name: `Projects of ${user.full_name}`,
                  href: paths.dashboard.management.board(userId),
                }
              : null,
            { name: group.title },
          ].filter((v) => !!v)}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Stack direction="column" spacing={3}>
          {/* SKILLS */}
          <GroupAggregatedSkills item={group} user={user} />

          {/* TABS */}
          <GroupTabs
            tabDefinitons={TABS_DEFINITION}
            metadata={{
              hasApplied: (group?.userDetails?.applicationCoverLetters || []).length > 0,
            }}
            currentTab={currentTab}
            handleChangeTab={handleChangeTab}
          />

          {/* TAB CONTENT */}
          <DisplayComponent key={`${group._id}-${currentTab}`} item={group} user={user} />
        </Stack>
      </Container>

      <ActionBar userId={user?._id} projectGroupId={group?._id} />
    </>
  );
}
