import PropTypes from 'prop-types';

import { Box, Button, Tooltip } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useScopes } from 'src/hooks/use-scopes';
import { useAuthState } from 'src/hooks/use-auth-state';

import { canSeeProjectApply } from 'src/utils/access-helper';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function GroupInfoApplicationHint({
  groupId,
  userId,
  posting,
  applicationCoverLetters,
}) {
  const { userId: authUserId } = useAuthState();
  const { hasScope } = useScopes();

  if (!canSeeProjectApply(hasScope)) {
    return null;
  }

  const hasCoverLetterForPosting = (applicationCoverLetters || []).some(
    (coverLetter) => coverLetter.projectId === posting.projectId
  );

  return (
    <Box>
      <Button
        component={RouterLink}
        to={paths.dashboard.management.group(
          groupId,
          userId !== authUserId ? userId : undefined,
          'apply',
          posting.projectId
        )}
        variant="contained"
        color="primary"
      >
        {hasCoverLetterForPosting ? (
          <Tooltip arrow top title="Cover Letter has been generated">
            <Iconify icon="eva:checkmark-circle-2-fill" width={16} sx={{ mr: 1 }} />
          </Tooltip>
        ) : (
          <Iconify icon="solar:stars-bold-duotone" width={16} sx={{ mr: 1 }} />
        )}
        Apply
      </Button>
    </Box>
  );
}

GroupInfoApplicationHint.propTypes = {
  userId: PropTypes.string,
  groupId: PropTypes.string,
  posting: PropTypes.shape({
    projectId: PropTypes.string,
    postedAt: PropTypes.string,
    platform: PropTypes.string,
    sourceLink: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    details: PropTypes.shape({
      descriptionLanguage: PropTypes.string,
      authorCompany: PropTypes.string,
      authorName: PropTypes.string,
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
  }),
  applicationCoverLetters: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      projectId: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      createdAt: PropTypes.string,
    })
  ),
};
