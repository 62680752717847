import * as Yup from 'yup';
import { useState } from 'react';
import Lottie from 'react-lottie';
import { useForm } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Card, Link, Alert, Button } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';
import { useAuthState } from 'src/hooks/use-auth-state';

import { bgGradient } from 'src/theme/css';
import animationData from 'src/assets/lotties/check.json';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

// ----------------------------------------------------------------------

const LoginSchema = Yup.object().shape({
  emailAddress: Yup.string().email().required('email address is required'),
  password: Yup.string().required('password is required'),
});

// ----------------------------------------------------------------------

export default function LoginForm() {
  const theme = useTheme();
  const isLoading = useBoolean(false);
  const isSuccess = useBoolean(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const { login } = useAuthState();
  const returnTo = searchParams.get('returnTo');

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      emailAddress: searchParams.has('email')
        ? decodeURIComponent(searchParams.get('email') || '')
        : '',
      password: '',
    },
  });

  const emailAddress = methods.watch('emailAddress');

  const forwardParams = new URLSearchParams();
  if (emailAddress) {
    forwardParams.set('email', emailAddress);
  }
  if (returnTo) {
    forwardParams.set('returnTo', returnTo);
  }

  const onSubmit = methods.handleSubmit(async (data) => {
    isSuccess.onFalse();
    isLoading.onTrue();
    setErrorMessage(null);

    try {
      await login(data.emailAddress, data.password);

      isLoading.onFalse();
      isSuccess.onTrue();

      await new Promise((resolve) => setTimeout(resolve, 2500));

      navigate(returnTo ? decodeURIComponent(returnTo) : paths.dashboard.management.board());
    } catch (error) {
      isLoading.onFalse();
      const message = error?.response?.data?.error || 'An error occurred. Please try again later.';
      setErrorMessage(message);
    }
  });

  return (
    <Box sx={{ maxWidth: '400px' }}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          opacity: 0.24,
          ...bgGradient({
            direction: '180deg',
            startColor: alpha(theme.palette.grey[900], 0),
            endColor: alpha(theme.palette.grey[900], 0.1),
          }),
        }}
      />

      <FormProvider methods={methods} onSubmit={onSubmit} sx={{ position: 'relative' }}>
        <Card sx={{ p: 3, position: 'relative' }}>
          <Stack
            direction="column"
            width={1}
            alignItems="flex-start"
            spacing={3}
            sx={{
              visibility: isSuccess.value === true ? 'hidden' : 'visible',
            }}
          >
            <Stack direction="column" width={1} alignItems="center" spacing={3}>
              <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
                👋 &nbsp;Welcome back!
              </Typography>

              <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>
                Don&apos;t have an account yet?{' '}
                <Link
                  component={RouterLink}
                  to={paths.waitlist}
                  sx={{ textDecoration: 'underline', color: theme.palette.grey[600] }}
                >
                  Sign up for our waitlist
                </Link>
              </Typography>
            </Stack>

            <Stack
              spacing={1}
              direction="column"
              justifyContent="flex-start"
              textAlign="left"
              sx={{
                width: '100%',
              }}
            >
              <RHFTextField
                name="emailAddress"
                label="Email Address"
                inputProps={{
                  sx: { backgroundColor: 'white', borderRadius: '8px' },
                }}
              />

              <RHFTextField
                type="password"
                name="password"
                label="Password"
                inputProps={{
                  sx: { backgroundColor: 'white', borderRadius: '8px' },
                }}
              />

              {errorMessage && (
                <Alert severity="error" sx={{ width: 1 }}>
                  {errorMessage}
                </Alert>
              )}
            </Stack>

            <Stack direction="column" width={1} spacing={1.5}>
              <LoadingButton
                type="submit"
                loading={isLoading.value}
                color="primary"
                size="large"
                variant="contained"
                endIcon={<Iconify icon="solar:alt-arrow-right-linear" width={24} />}
                width={1}
              >
                Sign in
              </LoadingButton>
              <Button
                LinkComponent={RouterLink}
                href={`${paths.forgotPassword}${forwardParams.size > 0 ? `?${forwardParams}` : ''}`}
                color="inherit"
                size="small"
                variant="text"
                width={1}
              >
                Forgot your password?
              </Button>
            </Stack>
          </Stack>

          {isSuccess.value === true && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Stack spacing={1.5} direction="column" sx={{ minWidth: '320px' }}>
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  width={196}
                  height={196}
                />
                <Stack spacing={1.5} direction="row" alignItems="center" justifyContent="center">
                  <Typography
                    variant="subtitle2"
                    sx={{ textAlign: 'center', fontWeight: 'normal' }}
                  >
                    You will be redirected in a moment..
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          )}
        </Card>
      </FormProvider>
    </Box>
  );
}
