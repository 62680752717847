import { Chip, Link, Tooltip } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useUserById } from 'src/hooks/use-user-by-id';

import { fDateTime } from 'src/utils/format-time';
import { isInTrialPhase } from 'src/utils/is-in-trial-phase';
import { hasActiveSubscription } from 'src/utils/has-active-subscription';
import { isInExtendedTrialPhase } from 'src/utils/is-in-extended-trial-phase';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function PlanLabel() {
  const { data: user, isLoading } = useUserById();

  if (isLoading) {
    return null;
  }

  if (hasActiveSubscription(user)) {
    return (
      <Link component={RouterLink} to={paths.dashboard.management.billing(user._id)}>
        <Chip
          label="Premium Plan"
          color="success"
          variant="soft"
          icon={<Iconify width={18} icon="solar:verified-check-linear" />}
        />
      </Link>
    );
  }

  if (isInTrialPhase(user) && isInExtendedTrialPhase(user)) {
    return (
      <Tooltip title={`Your Free Premium Plan ends at ${fDateTime(user.trialPeriodEnd)}`} arrow>
        <Link component={RouterLink} to={paths.dashboard.management.billing(user._id)}>
          <Chip
            label="Free Premium"
            color="success"
            variant="soft"
            icon={<Iconify width={18} icon="solar:verified-check-linear" />}
          />
        </Link>
      </Tooltip>
    );
  }

  if (isInTrialPhase(user)) {
    return (
      <Tooltip title={`Trial Phase ends at ${fDateTime(user.trialPeriodEnd)}`} arrow>
        <Link component={RouterLink} to={paths.dashboard.management.billing(user._id)}>
          <Chip
            label="Trial Phase"
            color="default"
            variant="soft"
            icon={<Iconify width={18} icon="solar:hourglass-line-duotone" />}
          />
        </Link>
      </Tooltip>
    );
  }

  return null;
}

PlanLabel.propTypes = {};
