import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useMemo, useState, useCallback } from 'react';

import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Card,
  Stack,
  Button,
  Divider,
  CardHeader,
  CardContent,
  LinearProgress,
} from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';
import { useUserById } from 'src/hooks/use-user-by-id';

import { cvFulfillsOnboarding } from 'src/utils/cv-fulfills-onboarding';

import Iconify from 'src/components/iconify';
import OptionalTooltip from 'src/components/optional-tooltip/optional-tooltip';
import { UserLocationForm } from 'src/components/user-location-form/work-location-toggle';
import { WorkLocationToggle } from 'src/components/work-location-toggle/work-location-toggle';

// ----------------------------------------------------------------------

export default function OnboardingWorkLocation({ cvDocument, percentage, prev, next }) {
  const api = useApi();
  const { data: user, mutate: mutateUser } = useUserById();
  const { enqueueSnackbar } = useSnackbar();

  const cvFulfilled = useMemo(() => cvFulfillsOnboarding(cvDocument, 'location'), [cvDocument]);

  const isSubmitting = useBoolean(false);

  const [activeWorkLocationValues, setActiveWorkLocationValues] = useState(
    user?.notificationSettings?.workLocation || [
      { value: 'remote', radius: undefined, enabled: true },
      { value: 'hybrid', radius: 50, enabled: false },
      { value: 'on-site', radius: 50, enabled: false },
    ]
  );

  const [activeAddress, setActiveAddress] = useState(user?.notificationSettings?.userLocation);

  const showAddressForm = useBoolean(!user?.notificationSettings?.userLocation);

  const onSubmit = useCallback(async () => {
    if (
      activeWorkLocationValues === user?.notificationSettings?.workLocation &&
      activeAddress === user?.notificationSettings?.userLocation &&
      user?.onboarding?.workLocation?.finished === true
    ) {
      next();
      return;
    }

    if (activeWorkLocationValues.filter((v) => v.enabled === true).length === 0) {
      enqueueSnackbar('You have to select at least one preferred work location!', {
        variant: 'error',
      });
      return;
    }

    isSubmitting.onTrue();

    try {
      const response = await api.patch(`/v1/users/${user._id}`, {
        source: 'onboarding',
        notificationSettings: {
          workLocation: activeWorkLocationValues,
          ...(activeAddress ? { userLocation: activeAddress } : {}),
        },
        onboarding: {
          workLocation: {
            finished: true,
          },
        },
      });

      mutateUser(response.data);
      next();
    } catch (error) {
      enqueueSnackbar('Update failed!', { variant: 'error' });
      console.error(error);
    } finally {
      isSubmitting.onFalse();
    }
  }, [
    activeWorkLocationValues,
    activeAddress,
    api,
    enqueueSnackbar,
    isSubmitting,
    mutateUser,
    next,
    user,
  ]);

  const toggleWorkLocationState = useCallback(
    async (value) => {
      const newValue = activeWorkLocationValues.map((v) =>
        v.value === value ? { ...v, enabled: !v.enabled } : v
      );

      setActiveWorkLocationValues(newValue);
    },
    [activeWorkLocationValues]
  );

  const changeWorkLocationRadius = useCallback(
    async (itemValue, radius) => {
      const newValue = activeWorkLocationValues.map((v) =>
        v.value === itemValue ? { ...v, radius } : v
      );
      setActiveWorkLocationValues(newValue);
    },
    [activeWorkLocationValues]
  );

  const changeAddress = useCallback(
    async (address) => {
      setActiveAddress({
        label: address.label,
        position: {
          type: 'Point',
          coordinates: [parseFloat(address.lon), parseFloat(address.lat)],
        },
      });

      showAddressForm.onFalse();
    },
    [showAddressForm]
  );

  const hasHybridOrOnSiteEnabled = useMemo(
    () =>
      activeWorkLocationValues.some((v) => ['hybrid', 'on-site'].includes(v.value) && v.enabled),
    [activeWorkLocationValues]
  );

  return (
    <Card sx={{ width: '480px', maxWidth: 'calc(100vw - 32px)', textAlign: 'left' }}>
      <CardHeader title="Where do you like to work?" />
      <CardContent>
        By sharing your ideal work environment, you&apos;ll receive suggestions that really match
        your expectations. Saving you time and keeping your options always interesting.
      </CardContent>

      <CardContent sx={{ pt: 0 }}>
        <Stack direction="column" spacing={3}>
          <WorkLocationToggle
            activeValues={activeWorkLocationValues}
            toggleValue={toggleWorkLocationState}
            changeRadius={changeWorkLocationRadius}
            justifyContent="center"
          />

          {hasHybridOrOnSiteEnabled && (
            <UserLocationForm
              activeAddressLabel={activeAddress?.label}
              formShown={showAddressForm.value}
              toggleForm={showAddressForm.onToggle}
              onChangeAddress={changeAddress}
              alignItems="flex-start"
              textAlign="left"
            />
          )}
        </Stack>
      </CardContent>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <CardContent>
        <Grid container sx={{ width: 1 }}>
          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            {prev && (
              <Button
                variant="text"
                color="inherit"
                startIcon={<Iconify icon="eva:arrow-left-fill" />}
                onClick={prev}
              >
                Back
              </Button>
            )}
          </Grid>

          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <LinearProgress
              color="inherit"
              value={percentage}
              variant="determinate"
              sx={{ width: '60px' }}
            />
          </Grid>

          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <OptionalTooltip
              show={!cvFulfilled}
              title="We're currently processing your cv, this will take a few seconds"
            >
              <LoadingButton
                type="submit"
                loading={isSubmitting.value || !cvFulfilled}
                variant="contained"
                color="primary"
                endIcon={<Iconify icon="eva:arrow-right-fill" />}
                onClick={onSubmit}
              >
                Continue
              </LoadingButton>
            </OptionalTooltip>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

OnboardingWorkLocation.propTypes = {
  percentage: PropTypes.number,
  prev: PropTypes.func,
  next: PropTypes.func,
  cvDocument: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fileInfo: PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      filePath: PropTypes.string.isRequired,
      fileSize: PropTypes.number.isRequired,
      fileType: PropTypes.string.isRequired,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    textContent: PropTypes.string,
    downloadUrl: PropTypes.string,
    error: PropTypes.string,
    extractedInfo: PropTypes.shape({
      check: PropTypes.shape({
        value: PropTypes.bool,
      }),
      checkInfo: PropTypes.shape({
        value: PropTypes.string,
      }),
      summary: PropTypes.shape({
        value: PropTypes.string,
      }),
      fullName: PropTypes.shape({
        value: PropTypes.string,
      }),
      location: PropTypes.shape({
        value: PropTypes.string,
      }),
      jobTitle: PropTypes.shape({
        value: PropTypes.string,
      }),
      interests: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      interestsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skills: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skillsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
};
