import PropTypes from 'prop-types';

import { Card, Stack, CardHeader } from '@mui/material';

import { useScopes } from 'src/hooks/use-scopes';

import CvDocumentsSidebarUploadContent from 'src/sections/cv-documents/sidebar-upload-content';

// ----------------------------------------------------------------------

export default function CvDocumentsSidebarUpload({ sx, userId }) {
  const { hasAllScopes } = useScopes();

  if (!hasAllScopes(['read:documents_own', 'write:documents_own'])) {
    return null;
  }

  return (
    <Card sx={{ p: 3, ...sx }}>
      <Stack spacing={2} alignItems="flex-start" direction="column">
        <CardHeader title="Your CVs" sx={{ m: 0, p: 0 }} />

        <CvDocumentsSidebarUploadContent userId={userId} />
      </Stack>
    </Card>
  );
}

CvDocumentsSidebarUpload.propTypes = {
  userId: PropTypes.string,
  sx: PropTypes.object,
};
