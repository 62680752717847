import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';

import { Stack, OutlinedInput } from '@mui/material';

export const OtpInput = ({ onChange, initial = '', spacing = 1, size = 40, hasError }) => {
  const inputsRef = useRef([]);
  const [otpValue, setOtpValue] = useState(
    Array.from({ length: 6 }, (_, i) => (initial || '')[i] || '')
  );

  // Focus the first otp input if there has not been provided initial value
  useEffect(() => {
    if ((initial || '').length === 0) {
      inputsRef.current[0].focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Whenever the input values change, send it to the parent component
  useEffect(() => {
    if (onChange) {
      onChange(otpValue.join(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpValue]);

  const setInputValue = (index, value) => {
    setOtpValue((prev) => {
      const changed = [...prev];
      changed[index] = value;
      return changed;
    });
  };

  const handleOnChange = (e) => {
    const { target } = e;
    const index = target.getAttribute('data-index');

    // limit the max chars for this input to 1
    if (e.target.value.length > 1) {
      e.target.value = e.target.value.charAt(0);
    }

    const { value } = e.target;

    setInputValue(index, value);

    const nextElementSibling = e.target.parentElement?.nextElementSibling?.firstChild;

    if (!nextElementSibling || !nextElementSibling.focus) {
      // no elements here that we could focus
      return;
    }

    // if we have no value, we just reset this text field
    if (!value) {
      return;
    }

    // if the value is not a number, we reset this text field
    if (!value.match('[0-9]{1}')) {
      return;
    }

    // if we have a numeric value, jump to the next field
    nextElementSibling.focus();
  };

  const handleOnKeyDown = (e) => {
    const { key, target } = e;
    const index = target.getAttribute('data-index');

    const previousElementSibling = target.parentElement?.previousElementSibling?.firstChild;

    if (key === 'Backspace') {
      // If this input is already empty and we're again hitting the backspace,
      // clear the previous input and focus on it
      if (target.value === '' && previousElementSibling) {
        setInputValue(index - 1, '');
        previousElementSibling.focus();
        e.preventDefault();
      } else {
        setInputValue(index, '');
      }
    }
  };

  const handleOnFocus = (e) => {
    e.target.select();
  };

  const handleOnPaste = (e) => {
    const pastedValue = e.clipboardData.getData('Text');
    const slicedValue = pastedValue.slice(0, 6);

    setOtpValue(
      Array.from({ length: 6 }, (_, i) => {
        const indexValue = slicedValue[i];
        return indexValue && indexValue.match('[0-9]{1}') ? indexValue : '';
      })
    );

    e.preventDefault();
  };

  return (
    <Stack direction="row" spacing={spacing}>
      {Array.from(Array(6).keys()).map((i) => (
        <OutlinedInput
          value={otpValue[i] || ''}
          key={i}
          error={hasError}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onFocus={handleOnFocus}
          onPaste={handleOnPaste}
          inputProps={{
            'data-index': i,
            maxLength: 1,
            style: { textAlign: 'center' },
          }}
          type="tel"
          inputRef={(el) => {
            inputsRef.current[i] = el;
          }}
          autoComplete={i === 0 ? 'one-time-code' : 'off'}
          sx={{
            height: size,
            width: size,
          }}
        />
      ))}
    </Stack>
  );
};

OtpInput.propTypes = {
  onChange: PropTypes.func,
  initial: PropTypes.string,
  spacing: PropTypes.number,
  size: PropTypes.number,
  hasError: PropTypes.bool,
};
