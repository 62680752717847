import axios from 'axios';

export function useApi() {
  const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_HOST_API,
    timeout: 10000,
    withCredentials: true,
  });

  axiosInstance.interceptors.request.use(async (config) => {
    if (!('Content-Type' in config.headers)) {
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  });

  return axiosInstance;
}
