import useSWR from 'swr';
import { useSnackbar } from 'notistack';
import { useRef, useEffect } from 'react';

import { swrConfig } from 'src/hooks/swr.config';

import { fetcher } from 'src/utils/api-fetcher';
import { generateHash } from 'src/utils/generate-object-hash';

export function useProjectGroup(id, userId, allowOriginalRequests = false) {
  const requestPayload = { userId, id };

  const storedRequestHash = useRef('');
  const { enqueueSnackbar } = useSnackbar();

  const newRequestHash = generateHash(requestPayload);
  const isOriginalRequest = storedRequestHash.current !== newRequestHash;

  const params = [
    `/v1/project-groups/${id}`,
    enqueueSnackbar,
    { params: { userId, original: allowOriginalRequests && isOriginalRequest } },
  ];

  if (isOriginalRequest) {
    storedRequestHash.current = newRequestHash;
  }

  const { data, error, isLoading, mutate } = useSWR(params, fetcher, swrConfig);

  const hasProcessingData =
    ['INITIALIZED', 'PROCESSING'].includes(data?.userDetails?.matching?.status) ||
    (data?.userDetails?.applicationCoverLetters || []).some((v) =>
      ['INITIALIZED', 'PROCESSING'].includes(v.llmStatus)
    );

  useEffect(() => {
    if (hasProcessingData) {
      const timeout = setInterval(() => {
        mutate();
      }, 5000);

      return () => clearInterval(timeout);
    }

    return () => {};
  }, [hasProcessingData, mutate]);

  return {
    data,
    error,
    isLoading,
    mutate,
  };
}
