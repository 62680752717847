import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useAuthState } from 'src/hooks/use-auth-state';

import { HEADER } from 'src/layouts/config-layout';

import RegisterForm from 'src/sections/register/register-form';

// ----------------------------------------------------------------------
export default function RegisterView() {
  const { authenticated } = useAuthState();
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) {
      navigate(paths.dashboard.root);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          backgroundImage: `url('/assets/background/overlay_7_gradient.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />

      <Box sx={{ pt: { xs: `${HEADER.H_MOBILE}px`, md: `${HEADER.H_DESKTOP}px` } }}>
        <RegisterForm />
      </Box>
    </>
  );
}
