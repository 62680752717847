import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { useAuthState } from 'src/hooks/use-auth-state';

export default function AuthUserAware({ children }) {
  const { authenticated, userId } = useAuthState();

  if (!authenticated || !userId) {
    return <Navigate replace to="/" />;
  }

  return children;
}

AuthUserAware.propTypes = {
  children: PropTypes.node.isRequired,
};
