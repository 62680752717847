import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Card, Stack, Button, Tooltip, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useApi } from 'src/hooks/use-api';
import { useScopes } from 'src/hooks/use-scopes';
import { useAuthState } from 'src/hooks/use-auth-state';
import { useProjectGroup } from 'src/hooks/use-project-group';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function GroupAdminActions({ user, item, selectedPostings, setSelectedPostings }) {
  const api = useApi();
  const navigate = useNavigate();
  const { userId: authUserId } = useAuthState();
  const { hasScope } = useScopes();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loadingAction, setLoadingAction] = useState(null);
  const { mutate } = useProjectGroup(item._id, user?._id);

  const executeAction = useCallback(
    async (action) => {
      setLoadingAction(action);

      try {
        const result = await api.patch(`/v1/project-groups/${item._id}`, {
          userId: user._id,
          action,
          postings: selectedPostings.join(','),
        });

        if (result.data.new) {
          enqueueSnackbar('Postings have been extracted into a new group', {
            autoHideDuration: 10000,
            action: (key) => (
              <Button
                LinkComponent={RouterLink}
                to={paths.dashboard.management.group(
                  result.data.new._id,
                  user?._id !== authUserId ? user?._id : undefined,
                  'info'
                )}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => closeSnackbar(key)}
              >
                Open
              </Button>
            ),
          });
        }

        if (result.data.status === 'ok') {
          mutate(result.data.current);
        } else {
          navigate(
            paths.dashboard.management.board(user?._id !== authUserId ? user?._id : undefined)
          );
        }
      } catch (error) {
        console.error(error);

        if (error?.response?.data?.error) {
          enqueueSnackbar(error.response.data.error, { variant: 'error' });
        }
      } finally {
        setSelectedPostings([]);
        setLoadingAction(null);
      }
    },
    [
      api,
      authUserId,
      closeSnackbar,
      enqueueSnackbar,
      item._id,
      mutate,
      navigate,
      selectedPostings,
      setSelectedPostings,
      user._id,
    ]
  );

  if (!hasScope('admin:users') || !selectedPostings.length) {
    return null;
  }

  return (
    <Box sx={{ position: 'fixed', bottom: '30px', right: '30px' }}>
      <Card sx={{ p: 3 }}>
        <Stack direction="column" spacing={2} alignItems="center">
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {selectedPostings.length} posting{selectedPostings.length > 1 ? 's' : ''} selected
          </Typography>

          <Stack direction="row" spacing={0.5}>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={loadingAction === 'reset'}
              disabled={!!loadingAction}
              onClick={() => executeAction('reset')}
              endIcon={
                <Tooltip
                  arrow
                  placement="top"
                  title="Reset the group assignment and treat the posting as if it was newly found"
                >
                  <Iconify icon="solar:info-circle-bold" />
                </Tooltip>
              }
            >
              Reset
            </LoadingButton>

            <LoadingButton
              variant="contained"
              loading={loadingAction === 'new-group'}
              disabled={!!loadingAction}
              onClick={() => executeAction('new-group')}
              endIcon={
                <Tooltip
                  arrow
                  placement="top"
                  title="Create a new project group and assign the selected postings to it"
                >
                  <Iconify icon="solar:info-circle-bold" />
                </Tooltip>
              }
            >
              New Group
            </LoadingButton>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
}

GroupAdminActions.propTypes = {
  selectedPostings: PropTypes.arrayOf(PropTypes.string),
  setSelectedPostings: PropTypes.func,
  item: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    postingCount: PropTypes.number,
    postings: PropTypes.arrayOf(
      PropTypes.shape({
        projectId: PropTypes.string,
        postedAt: PropTypes.string,
        platform: PropTypes.string,
        sourceLink: PropTypes.string,
        status: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        details: PropTypes.shape({
          descriptionLanguage: PropTypes.string,
          authorCompany: PropTypes.string,
          authorName: PropTypes.string,
          hourlyRate: PropTypes.number,
          startDate: PropTypes.string,
          workload: PropTypes.string,
          durationInMonths: PropTypes.number,
          industry: PropTypes.string,
          contractType: PropTypes.string,
          workplace: PropTypes.string,
          location: PropTypes.string,
        }),
      })
    ),
    details: PropTypes.shape({
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
    skills: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
      references: PropTypes.arrayOf(PropTypes.string),
    }),
    userDetails: PropTypes.shape({
      opinion: PropTypes.shape({
        projectId: PropTypes.string,
        comment: PropTypes.string,
        level: PropTypes.number,
        createdAt: PropTypes.string,
      }),
      matching: PropTypes.shape({
        projectId: PropTypes.string,
        createdAt: PropTypes.string,
        status: PropTypes.string,
        result: PropTypes.shape({
          information: PropTypes.string,
          level: PropTypes.number,
          missingSkills: PropTypes.arrayOf(PropTypes.string),
          missingSkillsReferences: PropTypes.arrayOf(PropTypes.string),
          personalAnalysis: PropTypes.string,
        }),
      }),
      applicationCoverLetters: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          projectId: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          createdAt: PropTypes.string,
        })
      ),
    }),
  }),
  user: PropTypes.object,
};
