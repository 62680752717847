import PropTypes from 'prop-types';

import Link from '@mui/material/Link';
import { Alert } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { bgGradient } from 'src/theme/css';

// ----------------------------------------------------------------------

export default function ProjectApplicationRoboIntroSmallPaywall({ userId }) {
  const theme = useTheme();

  return (
    <Alert
      severity="warning"
      sx={{
        ...bgGradient({
          direction: '45deg',
          startColor: alpha(theme.palette.warning.light, 0.2),
          endColor: alpha(theme.palette.warning.main, 0.25),
        }),
      }}
    >
      <b>Automated Cover Letter Generation is currently only available to premium users.</b>
      <br />
      <br />
      You can still benefit from our personalized features by upgrading to a Premium membership.
      This will unlock exclusive access to our advanced cover letter generation and other premium
      services, making your project search even more efficient and tailored to your needs.
      <br />
      <br />
      To learn more about our Premium plans and take advantage of these extra benefits,{' '}
      <Link
        component={RouterLink}
        color="inherit"
        underline="always"
        sx={{ fontWeight: 'bold' }}
        to={paths.dashboard.management.billing(userId)}
      >
        please subscribe here
      </Link>
      .
    </Alert>
  );
}

ProjectApplicationRoboIntroSmallPaywall.propTypes = {
  userId: PropTypes.string.isRequired,
};
