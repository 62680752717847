import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useRef, useState, useEffect } from 'react';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { Box, Card, Button, Divider, CardHeader, CardContent, LinearProgress } from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';
import { useUserById } from 'src/hooks/use-user-by-id';

import animationData from 'src/assets/lotties/clap.json';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function OnboardingFinish({ percentage, prev, next }) {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { data: user, mutate: mutateUser } = useUserById();
  const animationRef = useRef(null);
  const [animationSize, setAnimationSize] = useState();

  const loading = useBoolean(false);

  const onSubmit = async () => {
    if (user?.onboarding?.total?.finished === true) {
      next();
      return;
    }

    loading.onTrue();

    try {
      const response = await api.patch(`/v1/users/${user._id}`, {
        source: 'onboarding',
        onboarding: {
          total: {
            finished: true,
          },
        },
      });

      mutateUser(response.data);
      next();
    } catch (error) {
      enqueueSnackbar('Update failed!', { variant: 'error' });
      console.error(error);
    } finally {
      loading.onFalse();
    }
  };

  useEffect(() => {
    if (!animationRef.current) {
      return;
    }

    const minimum = Math.min(animationRef.current.clientHeight, animationRef.current.clientWidth);
    setAnimationSize(minimum);
  }, []);

  return (
    <Card sx={{ width: '480px', maxWidth: 'calc(100vw - 32px)', textAlign: 'left' }}>
      <Box sx={{ position: 'relative' }}>
        <CardHeader title="You're all set!" />
        <CardContent>
          Welcome to projectfit.ai – and to the start of your exciting journey with us! You&apos;re
          now ready to explore all the perfect project fits –{' '}
          <b>and it&apos;s all free for the next two weeks</b>!
        </CardContent>
        <CardContent
          ref={animationRef}
          sx={{
            p: 0,
            height: '200px',
            maxHeight: '100%',
            maxWidth: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {animationSize && (
            <Box sx={{ width: `${animationSize}px`, height: `${animationSize}px` }}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                width="100%"
                height="100%"
              />
            </Box>
          )}
        </CardContent>
        <CardContent sx={{ pt: 0, mt: 0 }}>
          This trial period is our way of saying welcome, and we hope you make the most of it. Dive
          into projectfit.ai and discover projects that are just right for you.
        </CardContent>
      </Box>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <CardContent>
        <Grid container sx={{ width: 1 }}>
          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<Iconify icon="eva:arrow-left-fill" />}
              onClick={prev}
            >
              Back
            </Button>
          </Grid>

          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <LinearProgress
              color="inherit"
              value={percentage}
              variant="determinate"
              sx={{ width: '60px' }}
            />
          </Grid>

          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <LoadingButton
              loading={loading.value}
              variant="contained"
              color="primary"
              endIcon={<Iconify icon="material-symbols-light:rocket-launch" />}
              onClick={onSubmit}
            >
              START
            </LoadingButton>
          </Grid>
        </Grid>

        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} />
      </CardContent>
    </Card>
  );
}

OnboardingFinish.propTypes = {
  percentage: PropTypes.number,
  prev: PropTypes.func,
  next: PropTypes.func,
  cvDocument: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fileInfo: PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      filePath: PropTypes.string.isRequired,
      fileSize: PropTypes.number.isRequired,
      fileType: PropTypes.string.isRequired,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    textContent: PropTypes.string,
    downloadUrl: PropTypes.string,
    error: PropTypes.string,
    extractedInfo: PropTypes.shape({
      check: PropTypes.shape({
        value: PropTypes.bool,
      }),
      checkInfo: PropTypes.shape({
        value: PropTypes.string,
      }),
      summary: PropTypes.shape({
        value: PropTypes.string,
      }),
      fullName: PropTypes.shape({
        value: PropTypes.string,
      }),
      jobTitle: PropTypes.shape({
        value: PropTypes.string,
      }),
      interests: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      interestsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skills: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skillsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
};
