import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Card } from '@mui/material';

import GroupInfoPostingItem from 'src/pages/dashboard/management/group/info/posting-item';

// ----------------------------------------------------------------------

export default function GroupInfoPostings({ item, user, selectedPostings, setSelectedPostings }) {
  const [searchParams] = useSearchParams();
  const [expandedPosting, setExpandedPosting] = useState(
    searchParams.get('posting') || item.postings[0].projectId
  );

  const handleChange = (panel) => (_, isExpanded) => {
    if (item.postingCount === 1) {
      return;
    }

    setExpandedPosting(isExpanded ? panel : false);
  };

  const appliedToPostings = useMemo(
    () => [...new Set((item.userDetails?.applicationCoverLetters || []).map((v) => v.projectId))],
    [item.userDetails?.applicationCoverLetters]
  );

  return (
    <Card>
      {item.postings.map((posting, index) => (
        <GroupInfoPostingItem
          key={posting.projectId}
          userId={user._id}
          groupId={item._id}
          postingCount={item.postingCount}
          posting={posting}
          isExpanded={expandedPosting === posting.projectId}
          expand={handleChange(posting.projectId)}
          appliedToPostings={appliedToPostings}
          selectedPostings={selectedPostings}
          setSelectedPostings={setSelectedPostings}
        />
      ))}
    </Card>
  );
}

GroupInfoPostings.propTypes = {
  selectedPostings: PropTypes.arrayOf(PropTypes.string),
  setSelectedPostings: PropTypes.func,
  item: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    postingCount: PropTypes.number,
    postings: PropTypes.arrayOf(
      PropTypes.shape({
        projectId: PropTypes.string,
        postedAt: PropTypes.string,
        platform: PropTypes.string,
        sourceLink: PropTypes.string,
        status: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        details: PropTypes.shape({
          descriptionLanguage: PropTypes.string,
          authorCompany: PropTypes.string,
          authorName: PropTypes.string,
          hourlyRate: PropTypes.number,
          startDate: PropTypes.string,
          workload: PropTypes.string,
          durationInMonths: PropTypes.number,
          industry: PropTypes.string,
          contractType: PropTypes.string,
          workplace: PropTypes.string,
          location: PropTypes.string,
        }),
      })
    ),
    details: PropTypes.shape({
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
    skills: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
      references: PropTypes.arrayOf(PropTypes.string),
    }),
    userDetails: PropTypes.shape({
      opinion: PropTypes.shape({
        projectId: PropTypes.string,
        comment: PropTypes.string,
        level: PropTypes.number,
        createdAt: PropTypes.string,
      }),
      matching: PropTypes.shape({
        projectId: PropTypes.string,
        createdAt: PropTypes.string,
        status: PropTypes.string,
        result: PropTypes.shape({
          information: PropTypes.string,
          level: PropTypes.number,
          missingSkills: PropTypes.arrayOf(PropTypes.string),
          missingSkillsReferences: PropTypes.arrayOf(PropTypes.string),
          personalAnalysis: PropTypes.string,
        }),
      }),
      applicationCoverLetters: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          projectId: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          createdAt: PropTypes.string,
        })
      ),
    }),
  }),
  user: PropTypes.object,
};
