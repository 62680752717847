import PropTypes from 'prop-types';

import { GridExpandMoreIcon } from '@mui/x-data-grid';
import {
  Stack,
  Button,
  Tooltip,
  useTheme,
  Checkbox,
  Typography,
  AccordionSummary,
} from '@mui/material';

import { paths } from 'src/routes/paths';

import { useScopes } from 'src/hooks/use-scopes';
import { useActionEvents } from 'src/hooks/use-action-events';

import { fToNow } from 'src/utils/format-time';
import platformLabel from 'src/utils/platform-label';
import { canSeeProjectApply } from 'src/utils/access-helper';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function GroupInfoPostingHeader({
  userId,
  appliedToPostings,
  postingCount,
  posting,
  selectedPostings,
  setSelectedPostings,
}) {
  const { dispatch } = useActionEvents();
  const theme = useTheme();
  const { hasScope } = useScopes();

  return (
    <AccordionSummary
      id={`${posting.projectId}-header`}
      expandIcon={postingCount === 1 ? null : <GridExpandMoreIcon />}
      sx={{ backgroundColor: theme.palette.grey[100], px: 2 }}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
        sx={{ width: 1, flexShrink: 0 }}
      >
        <Stack
          direction="column"
          spacing={1}
          justifyContent="center"
          alignContent="center"
          sx={{ flexShrink: 0 }}
        >
          {/* platform icon */}
          <Tooltip
            title={`This project has been found on ${platformLabel(posting.platform)}`}
            arrow
            placement="top"
          >
            <img
              alt={platformLabel(posting.platform)}
              src={`/assets/icons/platforms/${posting.platform}.png`}
              height="20"
              width="20"
              style={{ filter: 'grayscale(100%)' }}
            />
          </Tooltip>

          {selectedPostings && setSelectedPostings && hasScope('admin:users') && (
            <Checkbox
              sx={{ p: 0, m: 0 }}
              checked={selectedPostings.includes(posting.projectId)}
              onChange={(event) => {
                if (event.target.checked) {
                  setSelectedPostings((prev) => [...prev, posting.projectId]);
                } else {
                  setSelectedPostings((prev) => prev.filter((id) => id !== posting.projectId));
                }
              }}
            />
          )}
        </Stack>

        {/* title and metadata */}
        <Stack direction="column" spacing={0} sx={{ flexGrow: 1 }}>
          <Stack direction="row" spacing={1} alignItems="flex-start">
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              {posting.title}
            </Typography>

            {canSeeProjectApply(hasScope) && appliedToPostings.includes(posting.projectId) && (
              <Tooltip arrow top title="Cover Letter has been generated">
                <Iconify
                  icon="eva:checkmark-circle-2-fill"
                  color={theme.palette.primary.main}
                  width={16}
                  height={16}
                  sx={{ flexShrink: 0, height: '20px' }}
                />
              </Tooltip>
            )}
          </Stack>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {fToNow(posting.postedAt)}
            {posting.details.authorCompany && ` by ${posting.details.authorCompany}`}
          </Typography>
        </Stack>

        {userId && hasScope('optimize:prompts') && (
          <Button
            href={paths.dashboard.management.projectDetail(userId, posting.projectId)}
            variant="outlined"
            sx={{ flexShrink: 0, display: { xs: 'none', md: 'flex' } }}
            startIcon={<Iconify icon="solar:alt-arrow-right-linear" width={16} />}
            onClick={(event) => event.stopPropagation()}
          >
            View Project
          </Button>
        )}

        {/* external link (only visible on MD) */}
        <Button
          href={posting.sourceLink}
          variant="text"
          sx={{ flexShrink: 0, display: { xs: 'none', md: 'flex' } }}
          target="_blank"
          startIcon={<Iconify icon="eva:external-link-fill" width={16} />}
          onClick={(event) => {
            dispatch('project-group__open-source', { projectId: posting.projectId });
            event.stopPropagation();
          }}
        >
          Open
        </Button>
      </Stack>
    </AccordionSummary>
  );
}

GroupInfoPostingHeader.propTypes = {
  userId: PropTypes.string,
  selectedPostings: PropTypes.arrayOf(PropTypes.string),
  setSelectedPostings: PropTypes.func,
  appliedToPostings: PropTypes.arrayOf(PropTypes.string),
  postingCount: PropTypes.number,
  posting: PropTypes.shape({
    projectId: PropTypes.string,
    postedAt: PropTypes.string,
    platform: PropTypes.string,
    sourceLink: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    details: PropTypes.shape({
      descriptionLanguage: PropTypes.string,
      authorCompany: PropTypes.string,
      authorName: PropTypes.string,
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
  }),
};
