import useSWR from 'swr';
import { useRef } from 'react';
import { useSnackbar } from 'notistack';

import { swrConfig } from 'src/hooks/swr.config';
import { useAuthState } from 'src/hooks/use-auth-state';

import { fetcher } from 'src/utils/api-fetcher';
import { generateHash } from 'src/utils/generate-object-hash';

export function useUserById(userId, allowOriginalRequests = false) {
  const requestPayload = { userId };

  const storedRequestHash = useRef('');
  const { enqueueSnackbar } = useSnackbar();
  const { userId: authUserId } = useAuthState();

  const newRequestHash = generateHash(requestPayload);
  const isOriginalRequest = storedRequestHash.current !== newRequestHash;

  let params = [
    `/v1/users/${userId || authUserId}`,
    enqueueSnackbar,
    {
      params: {
        original: allowOriginalRequests && isOriginalRequest,
      },
    },
  ];

  if (isOriginalRequest) {
    storedRequestHash.current = newRequestHash;
  }

  if (!userId && !authUserId) {
    params = null;
  }

  const { data, error, isLoading, mutate } = useSWR(params, fetcher, swrConfig);

  return {
    data,
    error,
    isLoading,
    mutate,
  };
}
