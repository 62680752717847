import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useAuthState } from 'src/hooks/use-auth-state';

import ForgotPasswordForm from 'src/sections/forgot-password/forgot-password-form';

// ----------------------------------------------------------------------
export default function ForgotPasswordView() {
  const { authenticated } = useAuthState();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) {
      navigate(
        searchParams.has('returnTo')
          ? decodeURIComponent(searchParams.get('returnTo'))
          : paths.dashboard.root
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          backgroundImage: `url('/assets/background/overlay_7_gradient.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />

      <Box>
        <ForgotPasswordForm />
      </Box>
    </>
  );
}
