import * as Yup from 'yup';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
import {
  Stack,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useSampleCoverLetters } from 'src/hooks/use-sample-cover-letters';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

// ----------------------------------------------------------------------

export default function SampleCoverLettersModal({ shown, onClose, userId, editItem }) {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSampleCoverLetters(userId);

  const CoverLetterSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
  });

  const defaultValues = {
    title: '',
    description: '',
  };

  const methods = useForm({
    resolver: yupResolver(CoverLetterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;

  useEffect(() => {
    setValue('title', editItem?.title || '');
    setValue('description', editItem?.description || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem?._id]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const payload = {
        ...data,
      };

      let result;

      if (editItem) {
        result = await api.put(`/v1/users/${userId}/sample-cover-letters/${editItem._id}`, payload);
      } else {
        result = await api.post(`/v1/users/${userId}/sample-cover-letters`, payload);
      }

      mutate(result.data.data);
      onClose();
      setValue('title', '');
      setValue('description', '');
    } catch (error) {
      enqueueSnackbar('Could not save cover letter!', { variant: 'error' });
      console.error(error);
    }
  });

  return (
    <Dialog open={shown} onClose={onClose} scroll="paper">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle sx={{ pb: 2 }}>Example Cover Letter</DialogTitle>

        <DialogContent>
          <Stack spacing={3} direction="column">
            <DialogContentText>
              You can share a few of your previous cover letters with us. This way, we can better
              understand your style and tone when generating new cover letters for you.
            </DialogContentText>

            <RHFTextField name="title" label="Titel" />
            <RHFTextField name="description" multiline rows={12} label="Cover Letter" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Save
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

SampleCoverLettersModal.propTypes = {
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  userId: PropTypes.string,
  editItem: PropTypes.object,
};
